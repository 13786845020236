import React,{useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import loadingCircle from '../../../../img/loading-circle-white.svg';
import {useTranslations} from 'cupman-utils';
import DynamicSum from '../../DynamicSum';
  import ProductsTable from '../../DynamicSum/ProductsTable';
  import ProductRow from '../../DynamicSum/ProductRow';
  import HowToSpread from '../../DynamicSum/HowToSpread';
import {SaveContext} from '../../../SaveContext'
import { myScrollTop } from '../../../utils';
import {LangContext} from 'cupman-utils';

var classNames = require('classnames');

const isValid = (data) => {
  if(data.tournament.dates.end && data.spreadEven !== undefined && data.name && data.products.filter(
      p => p.checked).length > 0
    ) {
      return true;
  }

  return false;
}

const strmin = (a,b) => (a<b) ? a:b;

export default function Content ({onUiChange, uiData, data, onDataChange}) {
  const T = useTranslations("TeamCollect");
  const history = useHistory();
  const showCheckboxes = data.products.length > 1 ? true : false;
  const save = useContext(SaveContext);
  const [isSaving, setIsSaving] = useState(false);

  return (
    <main className="content-wrapper">
      
      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          <h1>{T('Starta ny insamling')}</h1>
        </div>
      </div>
      <div>
        <div className="row gutter">

          {/* LEFT COLUMN */}
          <div className="col l-5 summary_info">
            <h2>{T('Steg 1: Upplägg')}</h2>

            <p>{T('I den här guiden kommer du skapa en insamling för X Y till Z.', data.team.club.name, data.team.name, data.tournament.cupName)}</p>
            <p>{T('Du behöver ange ett namn på insamlingen, när man måste ha betalt och vilka produkter som ska ingå.')}</p>
            <p>{T('Välj sedan om du själv vill fördela kostnaderna på deltagarna eller om alla ska betala lika mycket')}</p>
            
            <p>{T('I nästa steg i guiden kommer du få möjlighet att lägga till deltagarna')}</p>
            
            <h3>1. {T('Välj namn och datum')}</h3>
            <label htmlFor="team-collect-name">{T('Vad ska din insamling heta?')}</label>
            <input type="text" id="team-collect-name" value={data.name} onChange={e => {
              onDataChange({
                ...data,
                name: e.target.value
              });
            }}/>


              <div className="date-select-wrapper">
                <label htmlFor="date-picker_year">{T('När vill du att laginsamlingen ska avslutas?')}</label>
                <div className={classNames("date-select-wrapper__inner", !data.tournament.dates.end && "invalid")}>
                  <input placeholder="yyyy-mm-dd" id="date-picker_year" onFocus={() => {
                    if(!data.tournament.dates.end) onDataChange({
                      ...data,
                      tournament: {
                        ...data.tournament,
                        dates: {
                          ...data.tournament.dates,
                          end: data.tournament.dates.init
                        }
                      }
                    })
                  }} type="date" value={data.tournament.dates.end} min={moment().format('YYYY-MM-DD')} max={data.tournament.dates.lastEnd} onChange={e => {
                    console.log(e.target.value);
                    onDataChange({
                      ...data,
                      tournament: {
                        ...data.tournament,
                        dates: {
                          ...data.tournament.dates,
                          end: strmin(e.target.value, data.tournament.dates.lastEnd)
                        }
                      }
                    })
                  }} />
                </div>
                <p style={{fontSize:12}}>{T("The latest date you can choose is X", data.tournament.dates.lastEnd)}</p>
              </div>

            {false && <div className="info-highlight">
                <i className="fas fa-info-circle"></i> {T('Insamlingen måste avslutas senast: ')} <strong style={{color: 'var(--color-red-2)', margin: '0'}}>{data.tournament.dates.lastEnd}</strong>.
                
            </div>}
          </div>
          {/* RIGHT COLUMN */}

          <div className="col l-5">
            <DynamicSum status={1} productsLength={data.products.length}>
              <ProductsTable products={data.products} teamCashText={true}>
                {data.products.map((product, i) => <ProductRow product={product} checkBoxes={showCheckboxes} i={i} key={i} 
                  onProductChange={newProduct => onDataChange({
                    ...data,
                    products: data.products.map(_product => _product===product ? newProduct : _product)
                  })}
                />)}
              </ProductsTable>
              <HowToSpread 
                spreadEven={data.spreadEven} 
                onSpreadEvenChange={s => onDataChange({
                  ...data,
                  spreadEven: s})
                } />
            </DynamicSum>
          </div>
        </div>
      </div>

      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10 nav-btns" aria-live="assertive">
            <button onClick={() => {
              setIsSaving(true);

              let participants = data.participants;
              
              if(data.anonymous && !data.spreadEven) {
                participants = participants.filter(participant => !participant.anonymous)
              }

              onDataChange({
                ...data,
                participants: participants.map(participant => {
                  const partProducts = participant.products;

                  for(const product of data.products.filter(p => !p.checked)) {
                    const pos = partProducts.findIndex(index => index.id === product.id);
                    if (pos > -1) {
                      partProducts.splice(pos, 1)
                    }
                  }
                  
          
                  return {
                    ...participant,
                    products: partProducts.length > 0 ? partProducts : []
                  };
                }),
                anonymous: data.spreadEven && data.participants.filter(p => p.firstName).length === 0 ? true : false
              })


              save().then(()=> {
                setIsSaving(false);
                history.push(`/wizard/${data.id}/participants`);
                myScrollTop();
              });
            }} className={classNames('btn dark-primary next', {saving: isSaving})} disabled={!isValid(data) || isSaving}> {T('Deltagare')} {
              isSaving ? 
              <> 
                <span className="sr-only">{T('Autosparar, vänta')}</span>
                <img src={loadingCircle} alt="" className="loading-circle" />
              </> : <i className="far fa-angle-right"></i>
              }


            </button>
        </div>
      </div>
    </main>
  )
}