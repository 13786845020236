import React from 'react';
import {sumTheSumsCount, formatCurrency} from '../../utils';
import {useTranslations} from 'cupman-utils';

export default function ProductsTable({children, products, teamCashText, usedTeamCash=0}) {
  const T = useTranslations("TeamCollect");

  return (
    <>
      <fieldset>
        <legend className="sr-only">Produkter</legend>
        <table>
          <thead>
            <tr>
              <th>{T('Produkt')}</th>
              <th>{T('Antal')}</th>
              <th>{T('Summa')}</th>
            </tr>
          </thead>
          <tbody>
            {children}
            <tr style={{'background': 'transparent'}}>
              <td colSpan="4">
                <hr />
              </td>
            </tr>
            <tr className="summary-sum">
              <td colSpan="1">{T('Summa')}:</td>
              <td colSpan="2">{formatCurrency(sumTheSumsCount(products).total - usedTeamCash)}</td>
            </tr>
            {!!teamCashText &&
            <tr>
              <td colSpan="4" className="warning text-right">
              {T('Lagkassa kan läggas in senare.')}
              </td>
            </tr>
            }
          </tbody>
        </table>
      </fieldset>
    </>
  )
}