import React from 'react';
import {useTranslations} from 'cupman-utils';
import {formatCurrency} from '../../utils';

export default function LeftToPay ({collected}) {
  const T = useTranslations("TeamCollect");

  return (
    <>
      <fieldset>
      <legend className="sr-only">{T('Kvar att betala totalt')}</legend>
        <table>
          <tbody>
            <tr className="summary-sum">
              <td>{T('Inbetalat')}:</td>
              <td>{formatCurrency(collected)}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </>
  )

}