import React, {useState, useEffect} from 'react';
import '../css/merge.scss';
import '../css/overview.scss';

// COMPONENTS ==================================================
import Betabanner from './components/Betabanner';
import Header from './components/Overview/Header';
import Overview from './components/Overview/Overview';
import Footer from './components/Overview/Footer';
import LoadingAnimation from './components/LoadingAnimation';
import Modal from 'react-modal';
import {massageTheData, setCurrencyCode} from './utils';
import {SaveContext} from './SaveContext';
import {useHistory} from 'react-router-dom';
import { init as initIntercom } from "./intercom";
import { useAxios } from '../AxiosContext';
import { LangProvider } from 'cupman-utils/lib/LangContext';

Modal.setAppElement('#root');

// APP =========================================================

function App({teamFundId}) {
  const history = useHistory();
  const getUrl = "/rest/teamFund/" + teamFundId;
  const actionUrl = "/rest/teamFund/";

  const axios = useAxios();

  /*
  ===================
  UI DATA
  ===================
  */
  const initUiData = {
    betaBanner : true
  }

  // STATES
  const [newData, setNewData] = useState({}); // DATA CHANGE
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      try {
        const result = await axios.get(getUrl);
        const data = result.data;

        setCurrencyCode(data.tournament.currency.code);

        initIntercom({
          email:  data.team.leader.email,
          name:  data.team.leader.name,
          phone:  data.team.leader.phone
        })
        setNewData(massageTheData(data));
        
      } catch (error) {
        setIsError(error);
      }

      setIsLoading(false);
    }

    fetchData();

  }, [getUrl]);

  const promiseCall = (bol, url, action, data) => {

    return axios[action](url,data).then(
      (response) => {
        console.log(data);
        setNewData(massageTheData(response.data));
      }
    );
  }


  // STATES
  const [uiData, setUiData] = useState(initUiData); // UI CHANGE
  useEffect(() => {
    newData.status === 'setup' && history.replace(`/wizard/${newData.id}`);
  }, [newData.status, newData.id, history]);

  if (isError) {
    return <h2></h2>
  }

  // RENDER
  return (
    <SaveContext.Provider value={{promiseCall, actionUrl}}>
      <LoadingAnimation active={isLoading}/>
      {!isLoading &&
        <LangProvider lang={newData.lang}>
          <InnerOverviewApp teamFundId={teamFundId} newData={newData} setNewData={setNewData} uiData={uiData} setUiData={setUiData} />
        </LangProvider>
      }
      <Footer />
    </SaveContext.Provider>
  );
}

export default App;

function InnerOverviewApp({teamFundId, newData, setNewData, uiData, setUiData}) {
  return <>

    <Header />
    {<Overview id={teamFundId} data={newData} onDataChange={data => {
      setNewData(data);
      console.log(data);
    } } />}
    {uiData.betaBanner &&
      <Betabanner uiData={uiData} onUiChange={updatedUiData => setUiData(updatedUiData)} />}
  </>;
}
