import React, {useState, useContext} from 'react';
import {useTranslations} from 'cupman-utils';
import loadingCircle from '../../../img/loading-circle-white.svg';
import {SaveContext} from '../../SaveContext';
import ConfirmDialog from '../ConfirmDialog';
import Axios from 'axios';

export default function ParticipantContactForm ({contact:initContact, participant, index, onSave, onDelete, setModalOpen, id}) {
  
  const T = useTranslations("TeamCollect");
  const save = useContext(SaveContext);
  const [isSaving, setIsSaving] = useState(false);
  const [notify, setNotify] = useState(true);
  const [contact, setContact] = useState(initContact);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div style={{marginTop: "1rem"}}>
      <fieldset className="participant-contact"> 
        {initContact.firstName !== undefined && 
        <legend>{initContact.firstName + " " + initContact.lastName}</legend>
        }
        {initContact.firstName === undefined && <legend>{T('Lägg till ny kontakt')}</legend>}
        <div className="row">
          <div className="col s-5">
            <label htmlFor={`contact-first-name-${participant.id}${index}`}>{T('Förnamn')}</label>
            <input type="text" value={contact.firstName} id={`contact-first-name-${participant.id}${index}`} onChange={(e) => setContact({
              ...contact,
              firstName: e.target.value
            })} />
          </div>
          <div className="col s-5">
            <label htmlFor={`contact-last-name-${participant.id}${index}`}>{T('Efternamn')}</label>
            <input type="text" value={contact.lastName} id={`contact-last-name-${participant.id}${index}`} onChange={(e) => setContact({
              ...contact,
              lastName: e.target.value
            })} />
          </div>
        </div>
        <div className="row">
          <div className="col s-5">
          <label htmlFor={`contact-email-${participant.id}${index}`}>{T('E-postadress')}</label>
            <input type="text" value={contact.email} id={`contact-email-${participant.id}${index}`} onChange={(e) => setContact({
              ...contact,
              email: e.target.value
            })} />
          </div>
          <div className="col s-5">
            <label htmlFor={`contact-phone-${participant.id}${index}`}>{T('Mobiltelefon')}</label>
            <input type="text" value={contact.phone} id={`contact-phone-${participant.id}${index}`} onChange={(e) => setContact({
              ...contact,
              phone: e.target.value
            })} />
          </div>
        </div>
        <div className="col s-9" style={{position: 'relative', display: 'flex', marginTop: '1rem'}}>

          <input type="checkbox" checked={notify} id={`contact-notify-${participant.id}${index}`} onChange={(e) => setNotify(e.target.checked)} />

          <label htmlFor={`contact-notify-${participant.id}${index}`} style={{marginTop: '2px', marginLeft: '5px', color: 'var(--color-orange-1)'}}>
            <span className="black">{contact.id && T('Skicka påminnelse till kontakten när du sparat.')}
            {!contact.id && T('Skicka inbjudan till kontakten när du sparat.')}</span>
          </label>
        </div>
        <div className="col s-1"></div>
      </fieldset>
      <div className="row">
        <div className="col s-10">
          <div className="btns">
            <button className="btn save" type="button"
              disabled={!( contact.firstName && contact.lastName && /.+@.+\..+/.test(contact.email) && /^[^A-Za-z]+$/.test(contact.phone))} 
              onClick={() => {
                setIsSaving(true);
              
                const actionUrl = contact.id 
                  ? notify 
                    ? (`${save.actionUrl}${id}/contact/${contact.id}?notify=true`) 
                    : (`${save.actionUrl}${id}/contact/${contact.id}`)
                  : notify 
                    ? (`${save.actionUrl}${id}/participant/${participant.id}/contact?notify=true`)
                    : (`${save.actionUrl}${id}/participant/${participant.id}/contact`)

                const action = contact.id ? 'put' : 'post';

                //save.promiseCall(actionUrl, action, contact)
                Axios[action](actionUrl,contact).then((response) => {
                  console.log('RESPONSE', response.data);
                  onSave(response.data);
                  setIsSaving(false);
                  setModalOpen(false);

                }).catch((error) => {
                  console.log('FEL', error);

                  setIsSaving(false);
                  setModalOpen(false);

                });
              }}>
              {T('Spara kontakt')} {
                isSaving ? 
                <> 
                  <span className="sr-only">{T('Autosparar, vänta')}</span>
                  <img src={loadingCircle} alt="" className="loading-circle" />
                </> : <i className="fas fa-check"></i>
                }
            </button>
            <ConfirmDialog visible={isOpen} setVisibility={setIsOpen} functionToRun={() => {
              const actionUrl = `${save.actionUrl}${id}/contact/${contact.id}`;
              
              Axios['delete'](actionUrl).then((response) => {
                console.log('RESPONSE', response.data);
                onDelete(contact);

                setIsSaving(false);
                setModalOpen(false);

              }).catch((error) => {
                console.log('FEL PÅ DELETE', error);

                setIsSaving(false);

              });
            }} sendTxt={T('Ta bort')} msg={T('Vill du verkligen ta bort kontaktperson: X Y?', contact.firstName, contact.lastName)} />

            {participant.contacts.length > 1 && <button type="button" className="btn delete" onClick={() => {
              setIsOpen(true);
            }}>{T('Radera kontakt')} <i className="fas fa-trash-alt"></i></button>}
          </div>
        </div>
      </div>
    </div>
  )
}