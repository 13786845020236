import React, {useState} from 'react';
import Modal from 'react-modal';
import {useTranslations} from 'cupman-utils';


export default function ButtonPayUrl({contact, participant}) {
  const T = useTranslations("TeamCollect");
  
  const [isOpen, setModalOpen] = useState(false);
  
  const closeModal = () => {
    setModalOpen(false);
  }


  return (
    <>
      <button className="btn-contact" onClick={() => setModalOpen(true)}>
        {T('Betallänk')}
      </button>
      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}>

        <h2>{T('X betalningslänk för Y:', `${contact.firstName} ${contact.lastName}s`, `${participant.firstName} ${participant.lastName}`)}</h2>
        <p>{T('Du kan antingen dela betallänken till X själv, eller används den direkt för att betala genom att klicka på den.',contact.firstName)}</p>
        
        <div style={{marginTop: "1rem"}}>
          <a href={contact.economy.payUrl} target="_blank" rel="noreferrer noopener" style={{color: 'var(--color-green-1)'}}>{contact.economy.payUrl}</a>
        </div>
        <div className="btns" style={{marginTop: "1rem"}}>
          <button className="btn save" onClick={() => navigator.clipboard.writeText(contact.economy.payUrl)}>{T('Kopiera betalningslänk')} <i className="far fa-copy" style={{marginLeft: '.3rem'}}></i></button>
        </div>
        
        
        

        <button onClick={() => setModalOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>
      </Modal>
    </>
  )
}
