import React from 'react';
import {useTranslations} from 'cupman-utils';
import {formatCurrency} from '../../utils';
import ProgressBarPaid from './ProgressBarPaid';
import { Link } from 'react-router-dom';
import moment from "moment";
import { useAxios } from '../../../AxiosContext';

export default function SummaryItem({summary, onDataChange}){

    const T = useTranslations("TeamCollect");

    const daysLeft = (summary) => {
        const given = moment(summary.endDate, "YYYY-MM-DD");
        const current = moment().startOf('days');
    
        if(given.diff(current, 'days') > 0) {
          return given.diff(current, 'days');
        } else {
          return '0';
        }
      }

      
    const axios = useAxios();



    return (<div key={summary.id} className="summary row gutter">
          
        
        <div className="col l-10">
        <h2 style={{marginTop: 0}}>
            <span className="fa-stack fa-1x">
                {summary.status==='settled' && <>
                <i className="fas fa-circle fa-stack-2x" aria-hidden="true" style={{color: "var(--color-green-1)"}}></i>
                <i className="fas fa-check fa-stack-1x" aria-hidden="true" style={{color: "var(--color-white)"}}></i>
                </>}
                {summary.status==='complete' && <>
                <i className="fas fa-circle fa-stack-2x" aria-hidden="true" style={{color: "var(--color-orange-1)"}}></i>
                <i className="fas fa-hourglass-end fa-stack-1x fa-inverse" aria-hidden="true" style={{color: "var(--color-white)"}}></i>
                </>}
                {summary.status==='cancelled' && <>
                <i className="fas fa-circle fa-stack-2x" aria-hidden="true" style={{color: "var(--color-red-1)"}}></i>
                <i className="fas fa-undo-alt fa-stack-1x fa-inverse" aria-hidden="true" style={{color: "var(--color-white)"}}></i>
                </>}
                {summary.status==='setup' && <>
                <i className="fas fa-circle fa-stack-2x" aria-hidden="true" style={{color: "var(--color-blue-1)"}}></i>
                <i className="fas fa-pencil-alt fa-stack-1x fa-inverse" aria-hidden="true" style={{color: "var(--color-white)"}}></i>
                </>}
                {summary.status==='collecting' && <>
                <i className="fas fa-circle fa-stack-2x" aria-hidden="true" style={{color: "var(--color-blue-1)"}}></i>
                <i className="fas fa-ellipsis-h fa-stack-1x fa-inverse" aria-hidden="true" style={{color: "var(--color-white)"}}></i>
                </>}
                
            </span>
            {summary.name}
        </h2>
        <h3 style={{marginTop: 0}}>{summary.team.name} - {summary.team.leader.name}</h3>
        {summary.status === 'setup' && <div>{T('Insamlingen skapades X, men har inte publicerats ännu.', summary.creationDate)}</div>}
        {summary.status !== 'setup' && summary.status !== 'cancelled' && summary.status !== 'settled' && <div>{T('X betalat av Y',formatCurrency(summary.amountCollected), formatCurrency(summary.amount))}</div>}
        {summary.status === 'settled' && <div>{T('X insamlat',formatCurrency(summary.amountCollected))}</div>}
        {summary.status !== 'setup' && summary.status !== 'settled' && summary.status !== 'cancelled' && <div> {T('X dagar kvar till Y',daysLeft(summary),summary.endDate)}</div>}
        {(summary.status === 'collecting' || summary.status === 'complete') && <ProgressBarPaid totalCount={summary.nrParticipants} paidCount={summary.nrParticipantsPaid} />}
        <div className="acc-container">
            {summary.status === 'setup' && <Link className={"btn dark-primary"} to={`/wizard/${summary.id}`}> {T('Färdigställ insamling')} <i className="fas fa-external-link"></i></Link>} 
            {summary.status === 'setup' && <button style={{marginLeft: "1em", marginRight: "1em"}} className={"btn dark-secondary"} onClick={()=>{
                axios['delete'](`/rest/teamFund/${summary.id}`).then(
                    (response) => {
                        onDataChange({
                            ...summary,
                            status: 'removed'
                        })
                    }
                );
            }}> {T('Ta bort')} </button>}
            {summary.status !== 'setup' && <Link className={"btn dark-primary"} to={`/overview/${summary.id}`}> {T('Visa insamling')} <i className="fas fa-external-link"></i></Link>}
        </div>
        </div>
    </div>)
}