import React from 'react';
import loadingCircle from '../../img/loading-circle.svg';
//import loadingDots from '../../img/loading-dots.svg';
var classNames = require('classnames');

export default function LoadingAnimation ({active}) {

  active ? document.body.classList.add('locked') : document.body.classList.remove('locked');

  return (
    <div className={classNames('loading', {active: active})}>
      <div className="loading-inner">
        <img src={loadingCircle} alt="" />
        {/*<img src={loadingDots} alt="" />*/}
      </div>
    </div>
  )
}