import React, {useState, useEffect, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import '../css/merge.scss';

// COMPONENTS ==================================================
import { useAxios } from '../AxiosContext';
import Betabanner from './components/Betabanner';
import Header from './components/Wizard/Header';
import StepSetup from './components/Wizard/Step-01/StepSetup';
import StepParticipants from './components/Wizard/Step-02/StepParticipants';
import StepProducts from './components/Wizard/Step-03/StepProducts';
import StepPublish from './components/Wizard/Step-04/StepPublish';
import Footer from './components/Wizard/Footer';
import LoadingAnimation from './components/LoadingAnimation';
import Modal from 'react-modal';
import {SaveContext} from './SaveContext';
import {massageTheData, setCurrencyCode} from './utils';
import { v4 as uuid } from 'uuid'; //eslint-disable-line
import { LangProvider } from 'cupman-utils/lib/LangContext';
import { init as initIntercom } from "./intercom";

function ErrorMsg ({isError, onClick}) {
  return(
    <div style={{display: isError ? 'block' : 'none'}}>
      <button onClick={onClick}>Försök igen</button>
    </div>
  )
}

let lastData = null;

Modal.setAppElement('#root');

// APP =========================================================
function App({teamFundId, step}) {
  const dataUrl = "/rest/teamFund/" + teamFundId;
  const history = useHistory();
  const axios = useAxios();

  // STATES
  
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [newData, _setNewData] = useState({});
  const setNewData = (data) => {
    _setNewData(data);
    lastData = data;
  }

  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      try {
        const result = await axios.get(dataUrl);
        const data = result.data;

        setCurrencyCode(data.tournament.currency.code);

        initIntercom({
          email:  data.team.leader.email,
          name:  data.team.leader.name,
          phone:  data.team.leader.phone
        })
        
        setNewData(massageTheData(data));
        console.log(massageTheData(data));

      } catch (error) {
        setIsError(error);
      }

      setIsLoading(false);
    }

    fetchData();

  }, [dataUrl, axios]);


  const [uiData, setUiData] = useState({
    betaBanner : true,
    loading: false
  }); // UI CHANGE

  const promiseSave = function(){
    return new Promise((resolutionFunction, errorFunction) => {
      console.log('LAST:', lastData);

      axios.put(dataUrl, lastData).then(function (response) {
          setNewData(massageTheData(response.data));
          resolutionFunction();
        }
      ).catch(() => console.log('ERROR'))

      .catch(function (error) {
        errorFunction(error);
        console.log(error);
      });  
    })
  }

  console.log('steg', step)
  
  useEffect(() => {
    (newData.status === 'collecting' && !newData.justPublished) && history.replace(`/overview/${newData.id}`);
  }, [newData.status, newData.id, history, newData.justPublished]);

  // RENDER
  return (
      <SaveContext.Provider value={promiseSave}>
        <LoadingAnimation active={isLoading}/>

        <ErrorMsg isError={isError} />

        {!isLoading &&
          <LangProvider lang={newData.lang}>
            <InnerWizardApp step={step} newData={newData} uiData={uiData} setUiData={setUiData} setNewData={setNewData} teamFundId={teamFundId} dataUrl={dataUrl} />
          </LangProvider>
        }

        <Footer />
      </SaveContext.Provider>
  );
}

export default App;

function InnerWizardApp({step, newData, uiData, setUiData, setNewData, teamFundId, dataUrl}) {
  return <>

    <Header step={step} status={newData.status} />


    {(!step || step === "setup") &&
      <StepSetup uiData={uiData} onUiChange={updatedUiData => {
        console.log("Content.onUiChange.1: ", updatedUiData);
        setUiData(updatedUiData);
      } } data={newData} onDataChange={updatedData => {
        console.log("Content.onDataChange.1: ", updatedData);
        setNewData(updatedData);
      } } />}
    {step === "participants" &&
      <StepParticipants uiData={uiData} onUiChange={updatedUiData => {
        console.log("Content.onUiChange.2: ", updatedUiData);
        setUiData(updatedUiData);
      } } data={newData} onDataChange={updatedData => {
        console.log("Content.onDataChange.2: ", updatedData);
        setNewData(updatedData);
      } } />}
    {step === "products" &&
      <StepProducts uiData={uiData} onUiChange={updatedUiData => {
        console.log("Content.onUiChange.3: ", updatedUiData);
        setUiData(updatedUiData);
      } } data={newData} onDataChange={updatedData => {
        console.log("Content.onDataChange.3: ", updatedData);
        setNewData(updatedData);
      } } />}
    {step === "publish" &&
      <StepPublish uiData={uiData} id={teamFundId} dataUrl={dataUrl + '/collect'} onUiChange={updatedUiData => {
        console.log("Content.onUiChange.4: ", updatedUiData);
        setUiData(updatedUiData);
      } } data={newData} onDataChange={updatedData => {
        console.log("Content.onDataChange.4: ", updatedData);
        setNewData(updatedData);
      } } />}
    {uiData.betaBanner &&
      <Betabanner uiData={uiData} onUiChange={updatedUiData => setUiData(updatedUiData)} />}
  </>;
}
