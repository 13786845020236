import React, {useEffect, useState} from 'react';
import { useAxios } from '../AxiosContext';
import Header from './components/PayApp/Header';
import Footer from './components/PayApp/Footer';
import LoadingAnimation from './components/LoadingAnimation';
import PayContent from './components/PayApp/PayContent';
import {useTranslations} from 'cupman-utils';
import { LangProvider } from 'cupman-utils/lib/LangContext';
import { setCurrencyCode } from './utils';

export default function PayApp ({teamFundId, contactId, secretId}) {

  const axios = useAxios();
  
  // Define if participant is known
  const anonymous = contactId ? false : true;

  const url = anonymous ?  `/rest/teamFund/${teamFundId}/pay/anonymous?secret=${secretId}` :  `/rest/teamFund/${teamFundId}/pay/contact/${contactId}?secret=${secretId}`;

  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState(); // DATA CHANGE

  useEffect(() => {
    axios.get(url).then(response => {
      console.log("success", response.data)

      setCurrencyCode(response.data.tournament.currency.code);

      setData(response.data);
      setIsLoading(false);
    }).catch((e) => {
      console.log(e);
      setIsError(e);
      setIsLoading(false);
    })
  }, [axios, url])

  return (
    <div id="payment">
      <LoadingAnimation active={isLoading}/>
      <Header />
      
      {(!isLoading && !isError) && 
        <LangProvider lang={data.lang}>
          <InnerPayApp anonymous={anonymous} data={data} url={url} contactId={contactId} />
        </LangProvider>
      }
      <Footer />

      {/*console.log('THE DATA:',data)*/}
    </div>
  )
} 

function InnerPayApp({anonymous, data, url, contactId}) {
  const T = useTranslations("TeamCollect");
  return <>
    <PayContent anonymous={anonymous} data={data} url={url} contactId={contactId} />

    {/*<div className="row gutter">
      <div className="col l-10" style={{ padding: '0 2rem 2rem 2rem' }}>
        <h1 style={{ marginBottom: '1rem' }}>{T('Ops, det där fungerade inte.')}</h1>
        {T('Något gick fel alternativt så är insamlingen avslutad.')}
      </div>
</div>*/}
  </>;
}
