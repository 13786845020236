import React, {useRef} from 'react';
import {useTranslations} from 'cupman-utils';

export default function ParticipantContact ({contact, index, onContactChange, isNew, onRemove, isSaving}) {
  const T = useTranslations("TeamCollect");
  const lockedNames = useRef(contact.firstName + " " + contact.lastName);
  // const contactLabel = isNew ? T('Lägg till ny kontakt') : T('Redigera ') + lockedNames.current;

  const updateContact = (keyValuePair) => {
    onContactChange({
      ...contact,
      ...keyValuePair
    })
  }

  return (
    <fieldset className="participant-contact">
      
      <div className="row">
        <div className="col s-5">
          <label htmlFor={`contact-first-name-${index}`}>{T('Förnamn')}</label>
          <input disabled={isSaving} type="text" value={contact.firstName} id={`contact-first-name-${index}`} onChange={e => updateContact({
            firstName: e.target.value
          })} />
        </div>
        <div className="col s-5">
          <label htmlFor={`contact-last-name-${index}`}>{T('Efternamn')}</label>
          <input disabled={isSaving} type="text" value={contact.lastName} id={`contact-last-name-${index}`} onChange={e => updateContact({
            lastName: e.target.value
          })} />
        </div>
      </div>
      <div className="row">
        <div className="col s-5">
          <label htmlFor={`contact-email-${index}`}>{T('Epostadress')}</label>
          <input disabled={isSaving} type="text" value={contact.email} id={`contact-email-${index}`} onChange={e => updateContact({
            email: e.target.value
          })} />
        </div>
        <div className="col s-5">
          <label htmlFor={`contact-phone-${index}`}>{T('Mobiltelefon')}</label>
          <input disabled={isSaving} type="text" value={contact.phone} id={`contact-phone-${index}`} onChange={e => updateContact({
            phone: e.target.value
          })} />
        </div>
      </div>
      {index > 1 && <button type="button" disabled={isSaving} className="btn delete" onClick={onRemove}>{T('Radera kontakt')} <i className="fas fa-trash-alt"></i></button>}
    </fieldset>
  )

}