import React from 'react';
import Modal from 'react-modal';
import {useTranslations} from 'cupman-utils';
import loadingCircle from '../../img/loading-circle-white.svg';

export default function ModalPreview ({setVisibility, visible, msg, functionToRun, sendTxt, isSaving}) {
  const T = useTranslations("TeamCollect");

  const closeModal = () => {
    setVisibility(false);
  }

  return (
    <Modal 
      className="modal confirm-dialog"
      overlayClassName="invisible"
      isOpen={visible}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      contentLabel='Bekräfta'>

      <div>
        <p>{msg}</p>
        <div className="buttons">

          <button className="btn prev" onClick={() => {
            setVisibility();
          }}>{T('Avbryt')}</button> 

          <button className="btn save" onClick={() => {
            functionToRun();
          }}>
            {sendTxt} {
            isSaving ? 
            <> 
              <span className="sr-only">{T('Autosparar, vänta')}</span>
              <img src={loadingCircle} alt="" className="loading-circle" />
            </> : <i className="far fa-angle-right"></i>
            }
          </button>
        </div>
      </div>
    </Modal>
  )
}