import React, {useState} from 'react';
import Modal from 'react-modal';
import {useTranslations} from 'cupman-utils';
import { divideMoney, formatCurrency } from '../../../utils';


export default function ModalTeamCash ({isOpen, teamCash, setIsOpen, saveTeamCash, participantsLength}) {

  const T = useTranslations("TeamCollect");
  const [cash, setCash] = useState(teamCash);

  const closeModal = () => {
    setIsOpen(false);
  }

  return (
    <Modal 
      className="modal"
      overlayClassName="overlay"
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      contentLabel={T('Lägg in lagkassa')}>

      <h2>{T('Lägg in lagkassa')}</h2>
      <p>{T('Lagkassan dras av från din insamling och fördelas jämnt på deltagarna.')}</p>
      <p>{T('Exempel: om du lägger in X i lagkassa kan varje av era Y deltagare maximalt nyttja Z av lagkassan.', formatCurrency(1000), participantsLength, formatCurrency(divideMoney(1000, participantsLength)))}</p>
        
      <div>
        <label htmlFor="team-cash">{T('Total lagkassa för alla spelare')}</label>
        <input id="team-cash" type="number" value={cash} onChange={(e) => setCash(parseInt(e.target.value))} />
        <div style={{marginTop : '.5rem'}}>{T('Varje deltagare kan nyttja X av lagkassan', formatCurrency(divideMoney(cash, participantsLength)))}.</div>
        <div className="btns">
          <button className="btn save" type="button" onClick={() => {
            saveTeamCash(parseInt(cash))
            setIsOpen(false)
            }}>
              {T('Spara lagkassa')} <i className="fas fa-check"></i>
          </button>
        </div>
      </div>

      <button onClick={() => setIsOpen(false)} className="close btn">
        <i className="fal fa-times"></i> 
        <span className="sr-only">{T('Stäng')}</span>
      </button>
    </Modal>
  )
}