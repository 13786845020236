import React from 'react';
import {useTranslations} from 'cupman-utils';

export default function CupInfo ({data}) {
  const T = useTranslations("TeamCollect");

  return (
    <div className="tournament-info">
      <h2 style={{marginBottom: '1rem'}}>
        {T('Turneringsinformation')}
      </h2>
      <div className="row" style={{marginBottom: "1rem"}}>
        <ul>
            <li>
              <h4>{T('Turneringsnamn')}</h4>
              <p>{data.tournament.cupName} {data.tournament.editionName} - <a href={data.tournament.cupUrl}>{T('Turneringshemsida')}</a></p>
            </li>
        </ul>
      </div>

      <div className="row">
        <div className="col l-5">
          <h3>{T('Laginformation')}</h3>
          <ul>
            <li>
              <h4>{T('Lagledare')}</h4>
              <span>{data.team.leader.name}</span>
            </li>
            <li>
              <h4>{T('Kategori')}</h4>
              {data.team.category}
            </li>
            <li>
              <h4>{T('Telefon')}</h4>
              {data.team.leader.phone}
            </li>
            <li>
              <h4>{T('E-post')}</h4>
              {data.team.leader.email}
            </li>
          </ul>
        </div>
        <div className="col l-5">
        <h3>{T('Klubbinformation')}</h3>
          <ul>
            <li>
              <h4>{T('Namn')}</h4>
              {data.team.club.name}
            </li>
            <li>
              <h4>{T('Adress')}</h4>
              {data.team.club.street}<br/>
              {data.team.club.postal} {data.team.club.city}
            </li>
            <li>
              <h4>{T('Telefon')}</h4>
              {data.team.club.phone}
            </li>
            <li>
              <h4>{T('E-post')}</h4>
              {data.team.club.email}
            </li>
          </ul>
        </div>
      </div>
      
    </div>
  )
}