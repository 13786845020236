import React, {useState} from 'react';
import Axios from 'axios';
import {massageTheData, divideMoney, usedTeamCash} from '../../../utils';
import ConfirmDialog from '../../ConfirmDialog';
import DynamicSum from '../../DynamicSum';
  import ProductsTable from '../../DynamicSum/ProductsTable';
  import ProductRow from '../../DynamicSum/ProductRow';
import ModalSent from './ModalSent';
import TableTrTeamCash from './TableTrTeamCash';
import KnownParticipants from './KnownParticipants';
import {useTranslations} from 'cupman-utils';
import {useHistory} from 'react-router-dom';
import loadingCircle from '../../../../img/loading-circle-white.svg';

export default function Content ({uiData, onUiChange, data, onDataChange, id, dataUrl}) {
  const T = useTranslations("TeamCollect");
  // const [isPublished, setIsPublished] = useState(false);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const history = useHistory();

  const publishSave = () => {
    return new Promise((resolutionFunction, errorFunction) => {
      Axios.post(dataUrl, data)
        .then((response) => {
          response.data.justPublished = true;
          onDataChange(massageTheData(response.data));
          resolutionFunction();
        }
      )
      .catch(function (error) {
        errorFunction(error);
        console.log(error);
      });
    });
  }

  // const getRightConfirmTxt = () => {
  //   return data.anonymous 
  //     ? <>
  //       {T('När du publicerat inbjudan får du en betallänk. Denna skickar du till samtliga som ska betala.')}<br/><br/>
  //       {T('Det går inte att ändra fördelningen av kostnader efter att du publicerat insamlingen.')}
  //     </>
  //     : <>
  //       {T('När du publicerar insamlingen skickas ett sms och ett epostmeddelande med betallänk till alla kontakter.')} <br/><br/>
  //       {T('Du kommer att skickas vidare till en översikt där du enkelt kan se vem som betalt. ')}
  //       {data.teamCash && T('Där kan du också betala in lagkassan.')} <br/><br/>
  //       {T('Det går inte att ändra fördelningen av kostnader efter att du publicerat insamlingen.')}
  //     </>
  //       ;
  // }

  const usedProductFraction = (product, participants) => {

    let t = 0,
        n = 0;

    for(const participant of participants) {
      for(const pproduct of participant.products){
        if(product.id === pproduct.id) {
          t += pproduct.fraction[0];
          n = pproduct.fraction[1];
        }
      }
    }

    if(t > 0) {
      return [t, n];
    }

    return [0,1];

  }

  const usedCount = (productCount, usedProductFraction) => {
    return divideMoney(usedProductFraction[0] * productCount, usedProductFraction[1]);
  }

  const usedAmount = (productAmount, usedProductFraction) => {
    return divideMoney(usedProductFraction[0] * productAmount, usedProductFraction[1]);
  }

  const used = (product) => ({
    ...product,
    count: usedCount(product.count, usedProductFraction(product, data.participants)),
    amount: usedAmount(product.amount, usedProductFraction(product, data.participants))
  });

  return (
    <main className="content-wrapper">
      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          <h1>{data.name}</h1>
        </div>
      </div>
      <div>
        <div className="row gutter">

          {/* LEFT COLUMN */}
          <div className="col l-5 paragraphs">
            <h2>{T('Steg 4: Publicera')}</h2>
            {!data.anonymous && <p>{T('När du publicerar din laginsamling kommer sms samt epostmeddelande att skickas till samtliga inlagda kontaktpersoner. Du kommer sedan att kunna följa upp all insamling på deltagarnivå')}.</p>}
            {data.anonymous && <>
              <p>{T('När du publicerar din laginsamling får du en betallänk som du kan skicka ut till deltagarna. Där fyller de i sina uppgifter och betalar')}.</p>
              <p>{T('Du får också tillgång till en översiktssida efter publiceringen där du ser vilka som betalat och hur mycket som är kvar att betala')}.</p>
              <h4>{T('Tips på sätt att dela betallänken via')}</h4>
              <p>{T('SMS, iMessage, epost, facebook, mfl')}</p>
            </>}
          </div>
          {/* RIGHT COLUMN */}
          <div className="col l-5">
            
            <DynamicSum publish={true}>
              <ProductsTable products={data.products.map(_p => used(_p))} usedTeamCash={data.teamCash ? usedTeamCash(data.teamCash.amount, data.participants, data.products):0} teamCashText={false}>
                {data.products.map((product, i) => 
                <ProductRow product={used(product)} checkBoxes={false} i={i} key={i} />)}

                {data.teamCash && usedTeamCash(data.teamCash.amount, data.participants, data.products) > 0 && <TableTrTeamCash usedTeamCash={usedTeamCash(data.teamCash.amount, data.participants, data.products).toLocaleString()} />}

              </ProductsTable>
            </DynamicSum>
          </div>
        </div>
      </div>
      <div className="row gutter">
        {!data.anonymous && 
          <KnownParticipants data={data} onDataChange={onDataChange} />
        }
      </div>
      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10 nav-btns">
          <button style={{"whiteSpace": "nowrap"}} onClick={() => history.push(`/wizard/${data.id}/products`)} className="btn delete previous">
            <i className="far fa-angle-left"></i> {T('Produkter')}
          </button>
          <button type="button" className="btn save" disabled={isSaving} style={{'paddingLeft' : '.8rem'}} onClick={() => {
            if (data.anonymous) {
              setIsSaving(true);
              publishSave().then(() => {
                window.location = data.overviewUrl;
              });
            } else {
              setConfirmDialogVisible(true);
            }

          }}>
            {!data.anonymous && T('Publicera och skicka inbjudan')} 
            {data.anonymous && T('Publicera')} 
            {!isSaving && <i className="fas fa-paper-plane"></i>}
            {isSaving && <img src={loadingCircle} alt="" className="loading-circle" />}
          </button>

          <ConfirmDialog visible={confirmDialogVisible} isSaving={isSaving} setIsSaving={setIsSaving} setVisibility={() => setConfirmDialogVisible(false)} functionToRun={() => {
            setIsSaving(true);
            publishSave().then(() => {
              window.location = data.overviewUrl;
            })
            }} sendTxt={T('Publicera')} msg={<>
              {T('När du publicerar insamlingen skickas ett sms och ett epostmeddelande med betallänk till alla kontakter.')} <br/><br/>
              {T('Du kommer att skickas vidare till en översikt där du enkelt kan se vem som betalt. ')}
              {data.teamCash && T('Där kan du också betala in lagkassan.')} <br/><br/>
              {T('Det går inte att ändra fördelningen av kostnader efter att du publicerat insamlingen.')}
            </>} />

          {/* 
          <ModalSent isPublished={isPublished} payLink={data.anonymousPayUrl} anonymous={data.anonymous} overviewUrl={data.overviewUrl} id={id} />
*/}
        </div>
      </div>
    </main>
  )
}