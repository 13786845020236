import React, {useState, useContext} from 'react';
import Participant from './Participant';
import ConfirmDialog from '../../ConfirmDialog';
import {useTranslations} from 'cupman-utils';
import {SaveContext} from '../../../SaveContext';
import ParticipantModal from './ParticipantModal';

export default function Participants ({participants, onSave}) {

  const [participantToRemove, setParticipantToRemove] = useState(undefined);
  const [participantToEdit, setParticipantToEdit] = useState(undefined);

  const T = useTranslations("TeamCollect");
  const save = useContext(SaveContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);


  console.log({participantToRemove});
  return (
    <>
      <div className="participants_wrapper">
        <h3><i className="fas fa-users"></i> {T('Deltagare')}</h3>
        {participants.length <= 0 && <span>{T('Lägg till deltagare via knappen ovan.')}</span>}

        {participants.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map((participant) =>  
          <Participant key={participant.id} 
            participant={participant} 
            openEditor={()=>setParticipantToEdit(participant)}
          />
        )}
        {participantToEdit && <ParticipantModal 
          isSaving={isSaving} 
          isDeleting={isDeleting} 
          participant={participantToEdit} 
          isNew={false} 
          isOpen={participantToEdit !== undefined && !participantToRemove} 
          closeEditor={()=>setParticipantToEdit(undefined)} 
          onSave={p => { 
            setIsSaving(true);
            onSave(participants.map(_p => _p.id===participantToEdit.id ? p : _p));
            save().then(() => {
              setIsSaving(false);
              setParticipantToEdit(undefined)
            });
          }} onDelete={()=>{
            setParticipantToRemove(participantToEdit)
          }}
        />}

        <ConfirmDialog 
          visible={participantToRemove !== undefined} 
          setVisibility={() => setParticipantToRemove(undefined)} 
          functionToRun={_p => {
            setParticipantToRemove(undefined);
            setIsDeleting(true);
            onSave(participants.filter(_p => _p.id!==participantToRemove.id));
            save().then(() => {
              setIsDeleting(false);
              setParticipantToEdit(undefined);
            })
          }} 
          sendTxt={T('Ta bort')} 
          msg={T('Vill du verkligen ta bort deltagare: X Y?', participantToRemove ? participantToRemove.firstName:'', participantToRemove ?participantToRemove.lastName:'')} 
        />
      </div>
    </>
  )
}