import React, {useState, useEffect} from 'react';
import '../css/merge.scss';
import '../css/overview.scss';

// COMPONENTS ==================================================
import Betabanner from './components/Betabanner';
import Header from './components/Overview/Header';
import Footer from './components/Overview/Footer';
import LoadingAnimation from './components/LoadingAnimation';
import Modal from 'react-modal';
import { useAxios } from '../AxiosContext';
import Summary from './components/Overview/Summary';
import { LangProvider } from 'cupman-utils/lib/LangContext';
import { setCurrencyCode } from './utils';

Modal.setAppElement('#root');

// APP =========================================================

export default function RegSummaryApp({postId}) {
  const getUrl = "/rest/teamFund/overview/" + postId;

  const axios = useAxios();

  const initUiData = {
    betaBanner : true
  }

  // STATES
  const [data, setData] = useState({}); // DATA CHANGE
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  

  useEffect(() => {

    const fetchData = async () => {
      setIsLoading(true);
      setIsError(false);

      try {
        const result = await axios.get(getUrl);

        setCurrencyCode(result.data.tournament.currency.code);

        setData(result.data);
      } catch (error) {
        setIsError(error);
      }

      setIsLoading(false);
    }

    fetchData();

  }, [getUrl]);

  const onDataChange = (tf) => {
    console.log(data.teamFunds);
    setData({
      ...data,
      teamFunds: data.teamFunds.filter(_tf => _tf.id !== tf.id || tf.status !== 'removed').map(_tf => _tf.id === tf.id ? tf : _tf)
    });
  }

  // STATES
  const [uiData, setUiData] = useState(initUiData); // UI CHANGE
  
  if (isError) {
    return <h2></h2>
  }

  // RENDER
  return (
    <>
      <LoadingAnimation active={isLoading}/>
      {!isLoading &&
        <LangProvider lang={data.lang}>
          <InnerRegSummaryApp postId={postId}  data={data} onDataChange={onDataChange} uiData={uiData} setUiData={setUiData}/>
        </LangProvider>
      }
      <Footer />
    </>
  );
}
function InnerRegSummaryApp({postId, data, onDataChange, uiData, setUiData}) {
  return <>
    <Header />
    <Summary id={postId} data={data} onDataChange={onDataChange} />
    {uiData.betaBanner &&
      <Betabanner uiData={uiData} onUiChange={updatedUiData => setUiData(updatedUiData)} />}
  </>;
}

