import React from 'react';
import {serviceFee, formatCurrency} from '../../../utils';
import {useTranslations} from 'cupman-utils';

export default function EvenSpreadParticipant ({dataPerParticipant, participantCount,randomParticipant}) {
  const T = useTranslations("TeamCollect");

  return (
    <>
      <div className="row gutter">
        <div className="col l-10">
          <h2><i className="fas fa-users"></i> {T('Summan fördelas jämnt på X deltagare', participantCount)}</h2>
          {serviceFee(randomParticipant) > 0 && <p><i className="fas fa-exclamation-circle"></i> {T('En serviceavgift läggs på alla deltagare.')}</p>}
          <div className="participant" style={{'marginTop' : '2rem'}}>
            <div className="participant-info">
              <span className="name">{T('Varje deltagare ska betala')}:</span>
              <div className="participant-sum">
                {formatCurrency(dataPerParticipant.total + serviceFee(randomParticipant))}
              </div>
            </div>
            <div className="col l-10 product-presentation">
              {dataPerParticipant.products.map((_p, i) => 
              <div className="row" key={i}>
                <div className="col l-10">{_p.name} <span className="right">{formatCurrency(_p.sum)}</span></div>
              </div>
              )}
              {dataPerParticipant.teamCash && 
              <div className="row">
                <div className="col l-10 red">{T('Lagkassa')} <span className="right">- {formatCurrency(dataPerParticipant.teamCash)}</span></div>
              </div>}
              {serviceFee(randomParticipant) > 0 && <div className="row">
                <div className="col l-10 italic">{T('Serviceavgift')}<span className="right">{formatCurrency(serviceFee(randomParticipant))}</span>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}