import React from 'react';

import {useTranslations} from 'cupman-utils';

import SummaryItem from './SummaryItem';


export default function Summary ({data, onDataChange, id}) {
  const T = useTranslations("TeamCollect");
  
  const collecting = data.teamFunds.filter(f=>f.status==='collecting');
  const setup = data.teamFunds.filter(f=>f.status==='setup');
  const complete = data.teamFunds.filter(f=>f.status==='complete');
  const settled = data.teamFunds.filter(f=>f.status==='settled');
  const cancelled = data.teamFunds.filter(f=>f.status==='cancelled');

  return (
    <main className="content-wrapper overview" style={{maxWidth: '900px', margin: 'auto', padding: "0 1rem 2rem 1rem"}}>

      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div style={{marginBottom: "2rem"}}>
          <h1 >{T('Alla insamlingar för X till Y - Z', data.postName, data.tournament.cupName, data.tournament.editionName)}
          </h1>
          <a className={"btn dark-primary"} style={{verticalAlign: "bottom"}} target="_blank" rel="noopener noreferrer" href={data.editUrl}> {T('Gå till anmälan')} <i className="fas fa-external-link"></i>
          </a>
        </div>
      </div>
      {setup.length > 0 && <div>
        <h2>{T('Insamlingar som inte startats')}</h2>
        {setup.map(summary => <SummaryItem key={summary.id} summary={summary} onDataChange={onDataChange}></SummaryItem>)}
      </div>}
      {complete.length > 0 && <div>
        <h2>{T('Insamlingar som ska avslutas')}</h2>
        {complete.map(summary => <SummaryItem key={summary.id} summary={summary} onDataChange={onDataChange}></SummaryItem>)}
      </div>}
      {collecting.length > 0 && <div>
        <h2>{T('Pågående insamlingar')}</h2>
        {collecting.map(summary => <SummaryItem key={summary.id} summary={summary} onDataChange={onDataChange}></SummaryItem>)}
      </div>}
      {settled.length > 0 && <div>
        <h2>{T('Genomförda insamlingar')}</h2>
        {settled.map(summary => <SummaryItem key={summary.id} summary={summary} onDataChange={onDataChange}></SummaryItem>)}
      </div>}
      {cancelled.length > 0 && <div>
        <h2>{T('Avbrutna insamlingar')}</h2>
        {cancelled.map(summary => <SummaryItem key={summary.id} summary={summary} onDataChange={onDataChange}></SummaryItem>)}
      </div>}
          
    </main>
  )
}