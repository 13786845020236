import React from 'react';
import {useTranslations} from 'cupman-utils';

export default function DynamicSum({children, status, productsLength}){

  const T = useTranslations("TeamCollect");
  const statusHeader = () => {
    if (status === 1) {
      if (productsLength <= 1) {
        return T('Samla in pengar för produkt')
      } else {
        return T('2. Välj produkter att samla in pengar för')
      }
    }
    if (status === 2 || status === 3) {
      return T('Valda produkter')
    }
    if (status < 3 || !status) {
      return T('Dessa produkter har fördelats ut')
    }
  }

  return (<div>
    <div className="summary">
      <h3>{statusHeader()}</h3>
      {children}
    </div>
  </div>
  )
}