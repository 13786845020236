import React, {useState, useContext} from 'react';
import {useTranslations} from 'cupman-utils';
import AnimateHeight from 'react-animate-height';
import klarna from '../../../img/klarna.png';
import { CmPay } from '../../CmPay';
import { LangContext } from 'cupman-utils';
import {formatCurrency, isEnded, animationDuration} from '../../utils';
import Axios from 'axios';

var classNames = require('classnames');

export default function TeamCash ({teamCashInfo:teamCash, team, endDate, id, teamFundStatus, totalCollected, participantsAllPaid, paymentInfo, tournament, onChange, setModalStopOpen, setModalCancelOpen}) {
    const T = useTranslations("TeamCollect");
    const {lang} = useContext(LangContext);
    const [accHeight, setAccHeight] = useState(0);
    const toggleAcc = () => setAccHeight(accHeight === 0 ? 'auto' : 0);
  
    const [fatalError, setFatalError] = useState(false);
    const [firstError, setFirstError] = useState(false);
    const [codeError, setCodeError] = useState(false);
  
    const overDue = () => {
      return isEnded(endDate) ? ' overdue' : '';
    }
  
    const status = teamCash ? (!teamCash.status ? 'unpaid' : teamCash.status) : "paid";
    const teamCashPayed = status === 'paid' || status === 'payLater';
    const teamCashAmount = teamCash ? teamCash.amount : 0;
    const teamCashDate = teamCash ? teamCash.payDate : undefined;
  
    const statusText = () => {
      const klarnaImg = `<img src="${klarna}" alt="Klarna" class="klarna" />`;
      if( teamCash && teamCash.status === 'refunded') {
       
        return T('<i class="fas fa-undo-alt"></i> Hej X! Den inbetalda lagkassan på <strong>Y</strong> återbetalades <strong>Z</strong>.', team.leader.name, formatCurrency(teamCashAmount), teamCash.refundDate);
      } else if (!teamCashPayed && !isEnded(endDate)) {
        // Unpaid
        return T('<i class="fas fa-exclamation-circle"></i> Hej X! Du har angett att ni har en lagkassa på <strong>Y</strong>. Vill du inte betala in lagkassan i insamlingen kan du betala den efter att insamlingen avslutats.', team.leader.name, formatCurrency(teamCashAmount), endDate);
      } else if(!teamCashPayed && isEnded(endDate)) {
        // Unpaid and overdue
        return T('<i class="fas fa-exclamation-circle"></i> Hej X! Du har angett att ni har en lagkassa på <strong>Y</strong>. Vill du inte betala in lagkassan i insamlingen kan du klicka på Slutför insamling nu så skickas det som redan samlats in till turneringen.', team.leader.name, formatCurrency(teamCashAmount));
      } else if (teamCash && status === 'paid' ) {
        // paid and done
        return T('<i class="fas fa-check-circle"></i> Hej X! Du har betalat in er lagkassa på <strong>Y</strong>. Lagkassan betalades <strong>Z</strong>.', team.leader.name, formatCurrency(teamCashAmount), teamCashDate);
      } else if (teamCash && status === 'payLater') {
        // Pay later with Klarna
        return T('<div class="align-middle">X <span style="margin-left: 1rem;">Hej Y! Du har betalt lagkassan med Klarnas "Betala senare". Det betyder att du inom 14 dagar sedan du betalade här måste betala Klarna Z.</span></div>', klarnaImg, team.leader.name, formatCurrency(teamCashAmount));
      } else {
        return '';
      }
    }

    return (
        <>
          <div className={'col l-10 leader-actions ' + status /*+ overDue()*/}>
            <div dangerouslySetInnerHTML={{__html: statusText()}}></div>
    
            {status !== 'refunded' && <div className={classNames('acc-container', {active: accHeight !== 0})}>
    
                {!teamCashPayed && participantsAllPaid && <button className={"btn save overview_btn"}  onClick={() => {
                  toggleAcc();
                }} aria-expanded={accHeight !== 0}> 
                  {T('Betala lagkassa och avsluta insamling nu')} 
                </button>}
    
                {!teamCashPayed && !participantsAllPaid && <button className={"btn save overview_btn"} onClick={() => {
                  toggleAcc();
                }} aria-expanded={accHeight !== 0}>
                  {T('Betala lagkassa nu')}
                </button>}

                <a className={"btn dark-primary overview_btn"} target="_blank" rel="noopener noreferrer" href={team.editUrl}> {T('Gå till anmälan')} <i className="fas fa-external-link"></i>
                </a>
    
                {/* {classNames('btn dark-secondary', {small: })} */}
                {totalCollected > 0 && <button className={"btn dark-secondary overview_btn"} onClick={() => setModalStopOpen(true)}> {T('Slutför insamling nu')} <i className="far fa-check"></i>
                </button>}
                
                <button className={"btn dark-secondary cancel overview_btn"} onClick={() => setModalCancelOpen(true)}> {T('Avbryt insamling nu')} <i className="fas fa-times-circle"></i>
                </button>
    
                {status !== 'paid' && status !== 'payLater' && <AnimateHeight
                  duration={ animationDuration }
                  height={ accHeight }
                >
                  <div>
                    <h3>{T('Hur vill du betala för lagkassan?')}</h3>
                    <CmPay 
                      paymentConfig={paymentInfo.paymentConfig}
                      orderItems={paymentInfo.orderItems}
                      currencyCode={paymentInfo.currencyCode} 
                      nationId={paymentInfo.nationId} 
                      email={paymentInfo.shipping.owner.email}
                      lang={lang}
                      edition={{
                        name: tournament.cupName + " " + tournament.editionName,
                        startTime: tournament.startTime,
                        endTime: tournament.endTime
                      }}
                      fatalError={fatalError}
                      firstError={firstError}
                      validate={() => {}}
                      performRequest={(extraPayload) => {
                        return Axios.post(`/rest/teamFund/${id}/pay/teamCash`, extraPayload);
                      }} 
                      onPaid={(response) => {
                        console.log("Happy times",response);
                        onChange(response.data);
                      }}
                      onError={(err) => {
                        if(err.response && err.response.status === 500) {
                          setFatalError(true);
                        } else if (err.code) {
                          setCodeError(err.message)
                        } else {
                          console.log(err)
                          setFirstError(err)
                        }
                      }}
                    />
                    {fatalError && <span className="error">{T('Ett oväntat fel uppstod när du försökte betala. Kontakta oss och kolla upp om din betalning gick igenom.')}</span>}
        
                    {firstError && firstError.response && firstError.response.status === 409 && <span className="error">{T('Det ser ut som att du redan har betalat.')}</span>}
        
                    {firstError && firstError.response && firstError.response.status === 404 && <span className="error">{T('Insamlingen är klar.')}</span>}
        
                    {codeError && !fatalError && <span className="error">{codeError}</span>}
                  </div>
                </AnimateHeight>}
              </div>}
          </div>
          <div className="col -10">
          
    
          </div>
        </>
      )

}