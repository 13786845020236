import React from 'react';
import phoneFrame from '../../../../img/smartPhone/phone-frame.jpg';


export default function SmartPhonePreview ({fund}) {
 
  return (
    <div className="smartphone-wrapper__outer">
      <div className="smartphone-wrapper">
        <img src={phoneFrame} className="smartphone-frame" alt="Yo" />
        <div className="msg-wrapper">
          <div className="msg-txt__wrapper">
            <p className="msg-txt">
            {fund.smsPreview}
            
            
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}