import React from 'react';
import logo from '../../../img/superinvite.svg';
import classNames from "classnames";
import { useTranslations } from "cupman-utils";

export default function Header ({step, status}) {
  const headerStep = step === undefined ? 'setup' : step;

  const T = useTranslations("TeamCollect");
  const availableSteps = [
    {
        code: "setup",
        title: T('Upplägg')
    },
    {
        code: "participants",
        title: T('Deltagare')
    },
    {
        code: "products",
        title: T('Produkter')
    },
    {
        code: "publish",
        title: T('Förhandsgranska')
    },
  ];
  var stepIndex = availableSteps.findIndex(s => s.code === headerStep);

  return (
    <header id="banner" className="banner">
      <div className="row gutter" style={{maxWidth: "1100px", margin: "auto"}}>
        <div className="col col-10">
          <div className="logo">
            <img src={logo} alt="Superinvite"></img>
          </div>
          <nav className="step-indicator">
            <ul>
              {availableSteps.map((el, i) => 
              <li key={i} className={classNames({
                active: el.code === headerStep,
                checked: i < stepIndex || status === 'collecting'
              })}>
                <span className="desktop" >{el.title}</span>
                <span aria-hidden="true" className="mobile">{i+1}</span> {(i < stepIndex || status === 'collecting') && <i className="fas fa-check"></i>}
              </li>)}
            </ul>
          </nav>
        </div>
      </div>
    </header>
  )
}