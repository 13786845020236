import React, {useContext, useState} from 'react';
import ParticipantForm from './ParticipantForm';
import ParticipantData from './ParticipantData';
import {useTranslations} from 'cupman-utils';
import { CmPay } from '../../CmPay';

import Axios from 'axios';
import { LangContext } from 'cupman-utils';
import CupInfo from './CupInfo';
import PayIntroText from './PayIntroText';

const isFatal = (err) => {
  return err && err.response && err.response.status === 500;
} 

export default function Content ({anonymous, data, contactId, url}) {
  console.log('THEDATA: ', data)
  const T = useTranslations("TeamCollect");
  const {lang} = useContext(LangContext);

  const initParticipant = anonymous ? {
    ...data.participant,
    contacts: [{
      firstName: '',
      lastName: '',
      phone: '',
      email: ''
    }]
  } : data.participant;
  const [participant, setParticipant] = useState(initParticipant);
  let errorMsg = null;

  const valid = {
    email: /^\s*[^\s,;]+@[^\s,;]+\.[^\s,;]+\s*$/.test(participant.contacts[0].email),
    tel: !/([A-Za-z]+|^$)/.test(participant.contacts[0].phone),
    partFirstName: participant.firstName,
    partLastName: participant.lastName,
    firstName: participant.contacts[0].firstName,
    lastName: participant.contacts[0].lastName
  }
  const isValid = (!valid.email || !valid.tel || !valid.partFirstName || !valid.partLastName || !valid.firstName || !valid.firstName) ? false : true;

  let [error, setError] = useState(null);

  if (error && isFatal(error)) {
    errorMsg = T('Ett oväntat fel uppstod när du försökte betala. Kontakta oss och kolla upp om din betalning gick igenom.');
  } else if (error && error.response) {

    if (error.response.status === 409) {
      errorMsg = T('Det ser ut som att du redan har betalat.');
    } else if(error.response.status === 404) {
      errorMsg = T('Insamlingen är klar.');
    }
  } else if (error) {
    console.log({...error, isValid})
    if (error.isValidationError ) {
      if(!isValid) {
        errorMsg = error.message;
      }
    } else {
      errorMsg = error.message;
    }
    
  } /*else if (error && firstError && !isValid) {
    errorMsg = T('Något gick fel, kolla igenom formuläret ovan.');
  } else if(error) {
    errorMsg = T('Något gick fel, försök igen.');
  }*/


  return (
    <main className="content-wrapper overview"  style={{maxWidth: '900px', margin: 'auto'}}>
      <div className="row gutter">
        <div className="col l-10">
          <h1><i className="far fa-trophy-alt" style={{marginLeft: '0'}}></i> {data.tournament.cupName} - {data.teamFundName}</h1>
        </div>
      </div>
      <PayIntroText anonymous={anonymous} data={data} />
      <div className="row gutter">
        <div className="col l-10">
          {(anonymous && (data.teamFundStatus !== 'cancelled' &&  data.teamFundStatus !== 'settled')) && <ParticipantForm valid={valid} firstError={error} fatalError={isFatal(error)} data={data} participant={participant} setParticipant={setParticipant} />}
          {!anonymous && <ParticipantData data={data} participant={participant} contactId={contactId} />}
        </div>
      </div>
      {((!anonymous && participant.economy.status !== 'paid' && participant.economy.status !== 'cancelled' && participant.economy.status !== 'refunded') || (anonymous && data.teamFundStatus !== 'cancelled' &&  data.teamFundStatus !== 'settled'))  && 
      <div className="row gutter" >
        <div className="col l-10">
        <h2>{T('Choose payment method')}</h2>
          <div style={{background: 'var(--color-white)', paddingBottom: '.1rem'}}>
            {/* PAY COMPONENT */}
            <CmPay 
              paymentConfig={data.paymentInfo.paymentConfig}
              orderItems={data.paymentInfo.orderItems}
              currencyCode={data.paymentInfo.currencyCode} 
              nationId={data.paymentInfo.nationId} 
              email={participant.contacts[0].email}
              lang={lang}
              fatalError={isFatal(error)}
              isValid={isValid} 
              firstError={error}
              edition={{
                name: data.tournament.cupName + " " + data.tournament.editionName,
                startTime: data.tournament.startTime,
                endTime: data.tournament.endTime
              }}
              validate={() => {
                if (anonymous) {

                  if (!valid.firstName || !valid.partFirstName || !valid.partLastName || !valid.lastName || !valid.tel || !valid.email) {
                    throw Object.assign(new Error(T('Det är fel i formuläret ovan. Kontrollera och testa igen.')), { isValidationError: true });
                  }
                  
                }
              }}
              performRequest={(extraPayload) => {
                
                return Axios.post(url, {
                  ...extraPayload, 
                  participant: participant
                });
              }} 
              onPaid={(data) => {
                console.log("Happy times",data);
                window.location = data.data.contact.economy.payUrl;
              }}
              onError={(err) => {
                setError(err);
              }}
            />
            
            {errorMsg && <span className="error">{errorMsg}</span>}

          </div>
        </div>
      </div>}
      <div className="row gutter">
        <div className="col l-10">
          <CupInfo data={data} />
        </div>
      </div>
    </main>
  )
}