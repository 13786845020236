
export const divideMoney = (amount, count) => Math.ceil(amount / count);
export const serviceFee = (participant) => participant.economy.serviceFee;
export const joinAnd = (items, word) => [items.slice(0, -1).join(', '), items.slice(-1)[0]].join(items.length < 2 ? '' : ` ${word} `);

export const myScrollTop = () =>{
  window.scrollTo({ 
    top: 0, 
    behavior: 'smooth' 
  });
}

var currencyCode = "kr";
export const setCurrencyCode = (currency) => {
  currencyCode = currency;
}
export const formatCurrency = (amount) => {
  return amount.toLocaleString() + " " + currencyCode; // + ' kr';
}


export const massageTheData = (_) => {
  var massaged =  ({
    ..._,
    emailExtraText: null ? '' : _.emailExtraText,
    spreadEven: undefined ? null : _.spreadEven,
    anonymousCount: _.anonymousCount === undefined && _.anonymous === true ? _.participants.length : 0,
    payLink: "https://supersecure.com/", // Just for test
    tournament: {
      ..._.tournament,
      dates: {
        ..._.tournament.dates,
        end: _.tournament.dates.end === undefined ? '' : _.tournament.dates.end
      }
    }
  })

  if( massaged.anonymous ){
    massaged.participants.forEach(p=>p.anonymous = true);
  }

  return massaged;
}

export const productCleared = (participants, product) => {
  const fraction = productClearedFraction(participants, product);

  return fraction.n > 0 && fraction.n === fraction.d;
}

export const productClearedFraction = (participants, product) => {
  if( participants.length === 0 ){
    return {n:0, d:0}; // No participants
  } else {
    var fraction =  participants.reduce((fraction, participant) => { // reduce each participant
      const inner = participant.products.reduce((fraction, p) => { // reduce each product
        const nominator = (p.id === product.id ? p.fraction[0] : 0); // if product.id exists in participant.product get nominator, else 0
        const denominator = (p.id === product.id ? p.fraction[1] : 0); // if product.id exists in participant.product  get denominator, else 0

        return {n: nominator + fraction.n, d: Math.max(denominator, fraction.d)}; // Return object: Add nominator to itself, denominator, return the largest value ( {n: 2, d: 4} )

      },{
          n:0,
          d:0
        }
      );
      return {n: inner.n+fraction.n, d:Math.max(inner.d, fraction.d)};
    }, {
      n:0,
      d:0
    });

    return fraction;
  }
}

export const toggleAcc = (tempHeight) => {
  return tempHeight === 0 ? 'auto' : 0
}

export const animationDuration = 250;

export const isEnded = (endDate) => {
  // End date passed?
  if (Date.now() > Date.parse(endDate)) {
    return true;
  }
  return false;
}

export const participantProductState = (participants, product) => {
  const obj = {};

  for(const participant of participants) {
    obj[participant.id] = 0;

    if(product.count >= participants.filter(pa => pa.products.find(pr => pr.id === product.id)).length) {

      for(const partProduct of participant.products) {
        if(partProduct.id === product.id) {
          obj[participant.id] = partProduct.fraction[0];
        }
      }
    }
  }

  return obj;
}

export const totalUsedSum = (participants, products) => {
  return (
    participants.reduce((num, participant) => num + calculateParticipantCosts(participant, products), 0)
  );
}

export const sumTheSumsCount = (products) => {
  let sumTheSums = {
    total: 0
  };

  for(let i = 0; i < products.length; i++) {
    if(products[i].checked) {
      sumTheSums.total += (products[i].amount);
    }
  }

  return sumTheSums;
}

export const usedTeamCash = (teamCash, participants, products) => {

  const teamCashPerParticipant = teamCash / participants.length; // Split teamcash into participants
  
  return (
    // Go through participants add up num (Initial value 0). Send participant, products and teamcash split to new function
    participants.reduce((num, _p) => num + usedParticipantTeamCash(_p, products, teamCashPerParticipant), 0)
  )
}



export const usedParticipantTeamCash = (participant, products, teamCashPerParticipant) => {

  // Get productSum, send participant and products
  const sum = calculateParticipantCosts(participant, products);
  // Return lowest value
  return Math.min(teamCashPerParticipant, sum);
}



export const calculateParticipantCosts = (participant, products) => {
  // Go through products add up num (Initial value 0). Send participant and product
  return products.reduce((num, product) => num + participantProductCost(participant, product), 0);
}



export function participantProductCost (participant, product) {
  // Return [participant.products.01] value
  for(const partProduct of participant.products) {

    if(partProduct.id === product.id){
      return divideMoney(
        product.amount * partProduct.fraction[0], partProduct.fraction[1]);
    }
  }

  return 0;

  /*return participant.products[product.id]
    // If value exists: Take product amount(total) * first fraction value to get and split it with the second fraction value
    ? divideMoney(
        product.amount * participant.products[product.id][0], 
        participant.products[product.id][1]) 
    : 0;*/
}