import React from 'react';
import {useTranslations} from 'cupman-utils';

export default function PayIntroText ({anonymous, data}) {

  const pStatus = data.participant.economy.status;

  const T = useTranslations("TeamCollect");

  return (
    <div className="row gutter" style={{marginTop: '1rem'}}>
        <div className="col l-10">

          {/* Known */}
            {!anonymous && <>
              {/* Paid */}
              {pStatus === 'paid' ? <div className={'p-status ' + pStatus}><p>
                <span className="fa-stack fa-1x">
                  <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
                  <i className="fas fa-check fa-stack-1x" style={{color: 'var(--color-green-1)'}}></i>
                </span> {T('Toppen! Tack för din betalning!')}
              </p></div> 
              
              : /* Refunded */ pStatus === 'refunded' ? <div className={'p-status ' + pStatus}><p>
                <span className="fa-stack fa-1x">
                  <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-black))'}}></i>
                  <i className="fas fa-undo-alt fa-stack-1x" style={{color: 'var(--color-yellow-1)'}}></i>
                </span> {T('Insamlingen är avbruten. Dina pengar är återbetalda.')}
              </p></div>

              : /* Refunded */ pStatus === 'cancelled' ? <div className={'p-status ' + pStatus}><p>
                <span className="fa-stack fa-1x">
                  <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
                  <i className="far fa-times fa-stack-1x" style={{color: 'var(--color-red-1)'}}></i>
                </span> {T('Insamlingen är avbruten.')}
              </p></div>

              : <p>
              {T('Nedan finner ni era uppgifter betalningsuppgifter för X. Betalningen avser Y i turneringen Z.', 
                  `${data.participant.firstName} ${data.participant.lastName}`, 
                  `${data.team.club.name} - ${data.team.name}`, 
                  `${data.tournament.cupName} - ${data.tournament.editionName}`)}
                <p style={{marginTop: '1rem'}}>
                <i style={{margin: '0'}}>{T('Mvh,')}<br/>
                  {data.team.leader.name}</i>
                </p>
              </p>}

            </>}
            
            
            {/* Anonymous */}
            {anonymous && <>
              {(data.teamFundStatus === 'collecting' || data.teamFundStatus === 'complete') && <p>
                {T('Fyll i era uppgifter nedan och betala sedan så att lagledaren X kan bokföra din betalning.', data.team.leader.name)} 
                <br/><br/>
                {T('Betalningen avser X i turneringen Y.', 
                    `${data.team.club.name} - ${data.team.name}`, 
                    `${data.tournament.cupName} - ${data.tournament.editionName}`)}
              </p>}
              {(data.teamFundStatus === 'cancelled' || data.teamFundStatus === 'settled') && <div className={'p-status ' + data.teamFundStatus}>
                {/* Cancelled */}
                {data.teamFundStatus === 'cancelled' && <p>
                  <span className="fa-stack fa-1x">
                    <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
                    <i className="far fa-times fa-stack-1x" style={{color: 'var(--color-red-1)'}}></i>
                  </span> {T('Insamlingen är avbruten.')}
                </p>}

                {/* ASettled */}
                {data.teamFundStatus === 'settled' && <p>
                <span className="fa-stack fa-1x">
                  <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
                  <i className="fas fa-check fa-stack-1x" style={{color: 'var(--color-green-1)'}}></i>
                </span> {T('Insamlingen är avslutad.')}
                </p>}
              </div>}

              <p style={{marginTop: '1rem'}}>
              <i style={{margin: '0'}}>{T('Mvh,')}<br/>
                {data.team.leader.name}</i>
              </p>
            </>}
          </div>
        
    </div>
  )
}