import React, {useState} from 'react';
//import {SaveContext} from '../../SaveContext';
//import loadingCircle from '../../../img/loading-circle-white.svg';
import {serviceFee, formatCurrency, animationDuration} from '../../utils';
import ButtonEditContact from './ButtonEditContact';
import ButtonNewContact from './ButtonNewContact';
import ButtonRemind from './ButtonRemind';
import ButtonPayUrl from './ButtonPayUrl';
import {useTranslations} from 'cupman-utils';
import AnimateHeight from 'react-animate-height';
var classNames = require('classnames');

export default function Participant({products, onChange, participant, editable, id}) {
  const T = useTranslations("TeamCollect");
  //const save = useContext(SaveContext);

  const [accHeight, setAccHeight] = useState(0);
  //const [isSaving, setIsSaving] = useState(false);
  //const [paused, setPaused] = useState(false);

  const toggleAcc = () => setAccHeight(accHeight === 0 ? 'auto' : 0);

  const stackIcons = icon => {
    // fa-stack-1x
    return (
      <>
        <span className="fa-stack fa">
          <i className="fas fa-circle fa-stack-2x"></i>
          {React.cloneElement(icon, {
            ...icon.props,
            className: icon.props.className + " fa-stack-1x"
          })}
        </span>
        <span className="sr-only">{T('Återbetald')}</span>
      </>
    );
  }

  const getStatusIcon = status => {
    if(status === 'pending') {
      return <i className="far fa-hourglass-half fa-inverse" style={{color: 'var(--color-yellow-1)'}}></i>;
    }
    if(status === 'sent') {
      return <i className="far fa-envelope fa-inverse" style={{color: 'var(--color-yellow-1)'}}></i>;
    }
    if(status === 'opened') {
      return <i className="far fa-envelope-open fa-inverse" style={{color: 'var(--color-red-2)'}}></i>;
    }
    if(status === 'paid') {
      return <i className="far fa-check fa-inverse" style={{color: 'var(--color-green-2)'}}></i>;
    }
    if(status === 'error') {
      return  <i className="fas fa-exclamation-triangle fa-inverse" style={{color: 'var(--color-red-2)'}}></i>;
    }
    if(status === 'cancelled') {
      return <i className="far fa-times fa-inverse" style={{color: 'var(--color-red-2)'}}></i>;
    }
    if(status === 'refunded') {
      return <i className="fas fa-undo-alt fa-inverse" style={{color: 'var(--color-green-2)'}}></i>;
    }
    return <i className="fas fa-question fa-inverse"></i>;
  }


  return (
  <div className={classNames('participant', {active: accHeight !== 0})} style={{marginBottom: '1rem'}}>
    <div className="participant-info" onClick={toggleAcc}>
      <span className="main-info">
        <span className="status">
        {getStatusIcon(participant.economy.status)}
          <span className="sr-only">{participant.economy.status}</span>
        </span>
        <span className="name">{participant.firstName} {participant.lastName} {participant.economy.status === 'paid' && <>
            <span style={{fontWeight: 'normal'}}>
              ({T('Betalade:')} {participant.economy.payDate})
            </span>
        </>}</span> 
      </span>
      <div className="participant-actions">

      {/*editable && <button className={`btn participant-state ${paused ? 'paused' : 'active'}`} disabled={isSaving} onClick={() => {
        setIsSaving(true);

        const url = paused ? `${save.actionUrl}participant/${participant.id}/start` : `${save.actionUrl}participant/${participant.id}/pause`;

        save.promiseCall(true, url, 'put').then(() => {
          setPaused(paused ? false : true);
          setIsSaving(false);
        }).catch(() => {
          setIsSaving(false);
        });
        
        }}>
        {isSaving ?
        <> 
          <span className="sr-only">{T('Autosparar, vänta')}</span>
          <img src={loadingCircle} alt="" className="loading-circle" />
        </> : paused ? <i className="far fa-play-circle"></i> : <i className="far fa-pause-circle"></i>
        }
        {!paused && <span>{T('Pausa')}</span>}
        {paused && <span>{T('Aktivera igen')}</span>}
      </button>*/}
      
      <span className="participant-amount">
        {formatCurrency(participant.economy.toPay)}
      </span>

      <button className="participant-accordion" onClick={() => {
        toggleAcc();
      }} aria-expanded={accHeight !== 0}><span className="sr-only">{T('Expandera')}</span></button>

      </div>
    </div>
    <AnimateHeight
        duration={ animationDuration }
        height={ accHeight }
      >

        <div className='participant-contacts' aria-hidden={accHeight !== 0}>
          <h3>{T('Kontaktpersoner')}</h3>
            {participant.contacts.map((contact, i) => 
              <div key={i}>
                <div className="contact row">
                  <div className="col" style={{width: "2rem"}}>
                  {stackIcons(getStatusIcon(contact.economy.status))}
                  </div>
                  <div className="col l-2">
                  <span>{contact.firstName} {contact.lastName}</span>
                  </div>
                  <div className="col l-2">
                    <span className={contact.phoneStatus==='error'?"red":""}>{contact.phone}</span>
                  </div>
                  <div className="col l-2">
                    <span className={contact.emailStatus==='error'?"red":""}>
                      {contact.email}
                    </span>
                  </div>
                  {editable && <div className="col l-4 actions">
                    <div className="action-button right">
                      <ButtonPayUrl contact={contact} participant={participant} />
                    </div>
                    <div className="action-button right">
                      <ButtonEditContact id={id} index={i} contact={contact} participant={participant} onSave={c => {
                        onChange({
                          ...participant,
                          contacts: participant.contacts.map(_c => _c === contact ? c : _c)
                        });

                      }} onDelete={(contact) => {
                        onChange({
                          ...participant,
                          contacts: participant.contacts.filter(_c => _c !== contact)
                        })
                      }}/>
                    </div>
                    <div className="action-button right">
                      <ButtonRemind id={id} contact={contact} onRemind={c => {
                        onChange({
                          ...participant,
                          contacts: participant.contacts.map(_c => _c === contact ? c : _c)
                        });

                      }}/>
                    </div>
                  </div>}
                  {/*contact.economy.status === 'paid' && <div className="col l-3" style={{display: 'block'}}>
                      <strong>{T('Betalade:')}</strong> {participant.economy.payDate}
                    </div>*/}
                </div>
                {contact.economy.errorMsg && 
                <div className="contact row">
                  <div className="col l-10">
                    <span style={{'fontFamily': 'Courier', 'fontWeight': '300'}} className={participant.economy.status === 'error' ? 'red' : ''}> <strong>Status:</strong> <span>{contact.economy.errorMsg}</span></span>
                  </div>
                </div>}
              </div>
            )}
            {editable && <div className="row">
              <div className="col l-10 right">
                <ButtonNewContact participant={participant} id={id} onSave={newC => {
                  onChange({
                    ...participant,
                    contacts: [...participant.contacts,
                    newC]
                  });
                }}/>
              </div>
            </div>}
          <div className="row product-information">
            <h3>{T('Produkter')}</h3>
            <div className="col l-10">
              {products.map((product, i) => 
                <div className="row" key={i}>
                  <div className="col s-10">
                    <span>{product.name}</span>
                    <span className="right">
                      {formatCurrency(participant.products.filter(p => product.id === p.id.toString()).reduce((total, product) => {
                        return total + product.amount}, 0)
                      )}
                    </span>
                  </div>
                </div>
              )}
              {participant.economy.teamCash > 0 && <div className="row">
                <div className="col s-10 red">
                  <span>{T('Lagkassa')}</span>
                  <span className="right">- {formatCurrency(participant.economy.teamCash)}</span>
                </div>
              </div>}
              {serviceFee(participant) > 0 && <div className="row">
                <div className="col l-10 italic">{T('Serviceavgift')}<span className="right">{formatCurrency(serviceFee(participant))}</span>
                </div>
              </div>}
            </div>

          </div>
        </div>

      </AnimateHeight>
  </div>
  )
}