import React from 'react';
import { useTranslations } from 'cupman-utils';
import OtherContacts from './OtherContacts';
import {formatCurrency} from '../../utils';

export default function ParticipantData ({data, contactId, participant}) {
  const T = useTranslations("TeamCollect");


  const thisContact = data.participant.contacts.find(p => p.id === contactId);
  const otherContacts = data.participant.contacts.filter(p => p.id !== contactId);
  const listItem = data.participant.products.length > 1 ? '- ' : '';


  return (
    <>
      <h2 style={{marginBottom: '1rem'}}>
        {participant.economy.status === 'paid' ? T('Kvitto') : T('Betalningsuppgifter')}
      </h2>
      <div className="tournament-info">
        <h3><i className="fas fa-user" aria-hidden="true"></i> {T('Participant information')}</h3>
        <div className="row">
          
          <div className="col s-5">
            <h4>{T('Förnamn')}</h4>
            <span>{data.participant.firstName}</span>
          </div>
          <div className="col s-5">
            <h4>{T('Efternamn')}</h4>
            <span>{data.participant.lastName}</span>
          </div>
        </div>

        <div className="products">
          <h3 style={{marginTop: '2rem'}}><i className="fas fa-shopping-basket" style={{marginLeft: '0'}}></i>  {data.participant.products.length > 1 ? T('Produkter') : T('Produkt')}</h3>
          <ul>
            {data.participant.products.map(
              product => <li key={product.id}>{listItem}{product.name}</li>
            )}
          </ul>
          <h3 style={{marginTop: '2rem'}}><i className="fas fa-coins" style={{marginLeft: '0'}}></i>  {T('Totalt')}</h3>
          <p>{formatCurrency(participant.economy.toPay)}</p>
          {participant.economy.teamCash > 0 && <p className="red" style={{fontSize: '.9rem', paddingTop: '.2rem'}}>{T('Lagkassa (X) är avdragen från summan.', formatCurrency(participant.economy.teamCash))}</p>}
        </div>

        <div className="your-info">
          <h3>
            <i className="fas fa-address-book" aria-hidden="true"></i> {T('Dina uppgifter')}
          </h3>
          <div className="row">
            <div className="col s-5">
              <h4>{T('Förnamn')}</h4>
              <span>{thisContact.firstName}</span>
            </div>
            <div className="col s-5">
              <h4>{T('Efternamn')}</h4>
              <span>{thisContact.lastName}</span>
            </div>
          </div>
          <div className="row">
            <div className="col s-5">
              <h4>{T('Telefon')}</h4>
              <span>{thisContact.phone}</span>
            </div>
            <div className="col s-5">
              <h4>{T('Epost')}</h4>
              <span>{thisContact.email}</span>
            </div>
          </div>
        </div>

        {otherContacts.length > 0 && <OtherContacts otherContacts={otherContacts} />}
      </div>
    </>
  )
}