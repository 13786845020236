import React, {useState, useContext} from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import sv from 'moment/locale/sv' // eslint-disable-line
import nb from 'moment/locale/nb' // eslint-disable-line
import {LangContext, useTranslations} from 'cupman-utils';
import {SaveContext} from '../../SaveContext'
import loadingCircle from '../../../img/loading-circle-white.svg';
import Axios from 'axios';
var classNames = require('classnames');


// TIME
const isValid = (milliSeconds) => {
  const minBetween = 15; // GLOBAL MINUTES BEFORE WE CAN SEND NEW MESSAGES
  const milliSecBetween = minBetween * 60000;
  const disableBefore = milliSeconds + milliSecBetween;

  if (disableBefore > Date.now()) {
    return false;
  }

  return true;
}
// // TIME

export default function ParticipantContact({contact, id, onRemind}) {
  const T = useTranslations("TeamCollect");
  const [isSendingSms, setSendingSms] = useState(false);
  const [isSendingEmail, setSendingEmail] = useState(false);
  const [isOpen, setModalOpen] = useState(false);
  const [sendMsg, setSendMsg] = useState({
    msgText: null,
    msgStatus: null
  })
  const closeModal = () => {
    setModalOpen(false);
  }
  const save = useContext(SaveContext);
  const {lang} = useContext(LangContext);
  moment.locale(lang);

  const formatDate = (milliSeconds) => moment(milliSeconds).format('D MMMM HH:mm');

  const getEmailStatusText = (status, errorMsg) => {
    if (status === 'ok') { 
      return <span className="green">{T('Epostmeddelande har skickats X', formatDate(contact.lastEmailSent))}</span>;
    }
    if (status === 'error') {
      return <span className="red">{T('Något gick fel när vi försökte skicka. (X)', errorMsg)}</span>;
    }
    if(status === 'pending') {
      return <span className="yellow">{T('Epostmeddelandet håller på att skickas.')}</span>;
    }
    if(status === 'unsent'){
      return <span className="yellow">{T('Epostmeddelandet har inte skickats än.')}</span>;
    }
    return false;
  }
  
  const getSMSStatusText = (status, errorMsg) => {
    if (status === 'ok') { 
      return <span className="green">{T('SMS-meddelande har skickats X', formatDate(contact.lastSMSSent))}</span>;
    }
    if (status === 'error') {
      return <span className="red">{T('Något gick fel när vi försökte skicka. (X)', errorMsg)}</span>;
    }
    if(status === 'pending') {
      return <span className="yellow">{T('SMS-meddelandet håller på att skickas.')}</span>;
    }
    if(status === 'unsent'){
      return <span className="yellow">{T('SMS-meddelandet har inte skickats än.')}</span>;
    }
    return false;
  }

  return (
    <>
      <button className="btn-contact" disabled={!isValid(contact.lastSMSSent) && !isValid(contact.lastEmailSent)} onClick={() => setModalOpen(true)}>
        {T('Påminn')}
      </button>
      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}>
        <h2>{T('Skicka påminnelse till')} {contact.firstName} {contact.lastName}</h2>
        <p>
          <strong>{T('Telefon')}</strong>: {contact.phone}<br />
          <strong>{T('Epost')}</strong>: {contact.email}
        </p>
        <div className="btns">
          <button className="btn dark-primary" disabled={!isValid(contact.lastSMSSent)} onClick={() => {
            setSendingSms(true);

            Axios['post'](`${save.actionUrl}${id}/contact/${contact.id}/sms`).then((response) => {
              onRemind(response.data);
              setSendingSms(false);
              setSendMsg({
                msgText: T('En SMS-påminnelse är nu skickad.'),
                msgStatus: true
              });
            }).catch(() => {
              setSendingSms(false);
              setSendMsg({
                msgText: T('SMS-meddelandet kunde inte skickas.'),
                msgStatus: false
              });
            })
          }}>
            <i className="far fa-phone"></i> {T('Skicka SMS-påminnelse')} {
              isSendingSms && 
              <> 
                <span className="sr-only">{T('Autosparar, vänta')}</span>
                <img src={loadingCircle} alt="" className="loading-circle" />
              </>
              }
          </button>
          <button className="btn dark-secondary" disabled={!isValid(contact.lastEmailSent)} onClick={() => {
            setSendingEmail(true);

            Axios['post'](`${save.actionUrl}${id}/contact/${contact.id}/email`).then((response) => {
              onRemind(response.data);
              setSendingEmail(false);
              setSendMsg({
                msgText: T('Ett påminnelse-epostmeddelande är nu skickat.'),
                msgStatus: true
              });
            }).catch((e) => {
              setSendingEmail(false);
              console.log(e);
              setSendMsg({
                msgText: T('Epostmeddelandet kunde inte skickas.'),
                msgStatus: false
              });
            })
          }}>
            <i className="far fa-envelope"></i> {T('Skicka epostpåminnelse')} {isSendingEmail && 
              <> 
                <span className="sr-only">{T('Autosparar, vänta')}</span>
                <img src={loadingCircle} alt="" className="loading-circle" />
              </>
              }
          </button>
        </div>
        
        {/* STATUS */}
        <div className="default-status">
          <h3 style={{margin: '0 0 .5rem'}}>{T('Status på tidigare utskick')}:</h3>
          <p>
            <i className="fas fa-envelope"></i> {getEmailStatusText(contact.emailStatus, contact.economy.errorMsg)}
          </p>
          <p>
          <i className="fas fa-comment-alt-lines"></i> {getSMSStatusText(contact.phoneStatus, contact.economy.errorMsg)}
          </p>
        </div>
        {/* // STATUS */}

        {/* SEND STATUS */}
        <div className={classNames('send-status', {sent: sendMsg.msgStatus, failed: sendMsg.msgStatus === false})}>
          {sendMsg.msgStatus === false && <i className="fas fa-exclamation-triangle"></i>} {sendMsg.msgStatus && <i className="fas fa-check-circle"></i>} {sendMsg.msgText}
        </div>
        {/* // SEND STATUS */}

        <button onClick={() => setModalOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>
      </Modal>
    </>
  )
}
