import React from 'react';
import Modal from 'react-modal';
import '../../../../css/email.scss';
import {useTranslations} from 'cupman-utils';

export default function ModalPreview ({isOpen, setIsOpen, data}) {
  const T = useTranslations("TeamCollect");

  
  const closeModal = () => {
    setIsOpen(false);
  }


  return (
    <Modal 
      className="modal email-preview"
      overlayClassName="overlay"
      isOpen={isOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick={true}
      contentLabel='zdhfhjdsd'>


    <div>
      {false && <div className="info-highlight" style={{'margin' : '35px 0 60px'}}>
      <h3 style={{margin : 0}}><i className="fas fa-exclamation-circle" aria-hidden="true"></i> {T('Exemplet nedan har genererats baserat på den första inlagda deltagaren och kan skilja sig från andra deltagares utskick.')}</h3>
      </div>}
      
      <div style={{"pointerEvents": "none"}} dangerouslySetInnerHTML={{__html: data.emailPreview}}>

      </div>

      <button onClick={() => setIsOpen(false)} className="close btn">
        <i className="fal fa-times"></i> 
        <span className="sr-only">{T('Stäng')}</span>
      </button>

      </div>
    </Modal>
  )
}