import React from 'react';
import {formatCurrency, participantProductCost, usedParticipantTeamCash, calculateParticipantCosts, serviceFee} from '../../../utils';
import {useTranslations} from 'cupman-utils';

export default function ParticipantEach ({participant, products, participantTeamCash}) {
  const T = useTranslations("TeamCollect");

  let sumThisParticipant = 0;
  const costTable = products.map(product => {
    const sumEachProduct = participantProductCost(participant, product);
    sumThisParticipant += sumEachProduct;

    return (
      <div className="row" key={product.id}>
        <div className="col l-10">
          <span>{product.name}</span> 
          <span className="right">{formatCurrency(sumEachProduct)}</span>
        </div>
      </div>
    )
  });

  const sumThisParticipantTeamCash = () => {
    if(sumThisParticipant - participantTeamCash > 0) {
      return Math.max(0, (sumThisParticipant + serviceFee(participant)) - participantTeamCash);
    }

    return Math.max(0, sumThisParticipant - participantTeamCash);
  }

  return (
    <div className="participant">
      <div className="participant-info">
        <span className="name">{participant.firstName} {participant.lastName}</span>
        <span className="participant-sum">
          <span>{T('Att betala')}:</span> <span className="bold">{formatCurrency(sumThisParticipantTeamCash())}</span>
        </span>
      </div>
      <div className="col l-10 product-presentation">
        {costTable}
        
        {usedParticipantTeamCash(participant, products, participantTeamCash) > 0 && 
        <div className="row">
          <div className="col l-10 red">{T('Lagkassa')} <span className="right">- {formatCurrency(usedParticipantTeamCash(participant, products, participantTeamCash))}</span></div>
        </div>
        }

        {(calculateParticipantCosts(participant, products) - usedParticipantTeamCash(participant, products, participantTeamCash) > 0) && 
          serviceFee(participant) > 0 && <div className="row">
          <div className="col l-10 italic">{T('Serviceavgift')}<span className="right">{formatCurrency(serviceFee(participant))}</span></div>
        </div>
        }
      </div>

    </div>
  )
}