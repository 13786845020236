import React from 'react';
import {useTranslations} from 'cupman-utils';
import {formatCurrency} from '../../utils';

var classNames = require('classnames');


export default function ParticipantForm ({data, participant, setParticipant, firstError, valid, fatalError}) {
  const T = useTranslations("TeamCollect");
  const listItem = data.participant.products.length > 1 ? '- ' : '';

  return (
    <>
      <div className="ano-products">
        <div className="row">
          <div className="col l-10">
            <h2 style={{marginTop: '0'}}>
              <i className="fas fa-shopping-basket" style={{marginLeft: '0'}}></i> {data.participant.products.length > 1 ? T('Produkter') : T('Produkt')} 
            </h2>
            <ul>
              {data.participant.products.map(
                product => <li key={product.id}>{listItem}{product.name}</li>
              )}
            </ul>
            <p style={{marginTop: '1rem'}}>{T('Totalt')}: <strong>{formatCurrency(participant.economy.toPay)}</strong></p>
          </div>
        </div>
      </div>
      <div>
        <h2 style={{marginBottom: '1rem'}}>
          {T('Fyll i era uppgifter')}
        </h2>

        <fieldset className="participant-contact">
          <legend><i className="fas fa-user" aria-hidden="true"></i>{T('Deltagare')}</legend>
          <div className="row">
            <div className="col s-5">
              <label>{T('Förnamn')}</label>
              <input type="text" disabled={fatalError} value={participant.firstName} className={classNames({'not-valid': firstError && !valid.partFirstName})} onChange={e => 
                setParticipant({
                  ...participant,
                  firstName: e.target.value
                })
              }></input>
              {(firstError && !valid.partFirstName) && <span className="red">{T('Förnamnet saknas')}</span>}
            </div>
            <div className="col s-5">
              <label>{T('Efternamn')}</label>
              <input type="text" disabled={fatalError} className={classNames({'not-valid': firstError && !valid.partLastName})} value={participant.lastName} onChange={e => 
                setParticipant({
                  ...participant,
                  lastName: e.target.value
                })
              }></input>
              {(firstError && !valid.partLastName) && <span className="red">{T('Efternamnet saknas')}</span>}
            </div>
          </div>
        </fieldset>

        <fieldset className="participant-contact">
          <legend><i className="fas fa-address-book" aria-hidden="true"></i> {T('Dina uppgifter - kontaktinformation')}</legend>
          <div className="row">
            <div className="col s-5">
              <label>{T('Förnamn')}</label>
              <input type="text" disabled={fatalError} className={classNames({'not-valid': firstError && !valid.firstName})} value={participant.contacts[0].firstName} onChange={e => {
                setParticipant({
                  ...participant,
                  contacts: [{
                    ...participant.contacts[0],
                    firstName: e.target.value
                  }]
                })
              }}></input>
              {(firstError && !valid.firstName) && <span className="red">{T('Förnamnet saknas')}</span>}
            </div>
            <div className="col s-5">
              <label>{T('Efternamn')}</label>
              <input disabled={fatalError} type="text" className={classNames({'not-valid': firstError && !valid.lastName})} value={participant.contacts[0].lastName} onChange={e => {
                setParticipant({
                  ...participant,
                  contacts: [{
                    ...participant.contacts[0],
                    lastName: e.target.value
                  }]
                })
              }}></input>
              {(firstError && !valid.lastName) && <span className="red">{T('Efternamnet saknas')}</span>}
            </div>
          </div>
          <div className="row">
            <div className="col s-5">
              <label>{T('Telefon')}</label>
              <input disabled={fatalError} type="tel" className={classNames({'not-valid': firstError && !valid.tel})} value={participant.contacts[0].phone} onChange={e => {
                setParticipant({
                  ...participant,
                  contacts: [{
                    ...participant.contacts[0],
                    phone: e.target.value
                  }]
                })
              }}></input>
              {(firstError && !valid.tel) && <span className="red">{T('Telefonnumret är felaktig')}</span>}
            </div>
            <div className="col s-5">
              <label>{T('Epostadress')}</label>
              <input disabled={fatalError} type="text" className={classNames({'not-valid': firstError && !valid.email})} value={participant.contacts[0].email} onChange={e => {
                setParticipant({
                  ...participant,
                  contacts: [{
                    ...participant.contacts[0],
                    email: e.target.value
                  }]
                })
              }}></input>
              {(firstError && !valid.email) && <span className="red">{T('Epostadressen är felaktig')}</span>}
            </div>
          </div>
        </fieldset>
      </div>
    </>
  )
}