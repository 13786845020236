import React from 'react';
import logo from '../../../img/superinvite.svg';

export default function Header () {

  return (
    <header id="banner" className="banner">
      <div className="row gutter" style={{maxWidth: "900px", margin: "auto"}}>
        <div className="col col-10">
          <div className="logo">
            <img src={logo} alt="Superinvite"></img>
          </div>
        </div>
      </div>
    </header>
  )
}