import React, {Suspense}from 'react';
import ReactDOM from 'react-dom';
import { LangProvider } from 'cupman-utils/lib/LangContext';
import WizardApp from './js/WizardApp';
import LoadingAnimation from './js/components/LoadingAnimation';
import OverviewApp from './js/OverviewApp';
import RegSummaryApp from "./js/RegSummaryApp";
import PayApp from './js/PayApp';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router, Switch, Route} from'react-router-dom';
import { AxiosProvider } from './AxiosContext';

/*
  team fund ids:
    29109787
    29109522
*/

const DEV =  process.env.NODE_ENV === 'development';

ReactDOM.render(
  <React.StrictMode>
    <LangProvider lang="en">
      <Suspense fallback={<LoadingAnimation />}>
        <AxiosProvider>
          <Router>
            <Switch>
              <Route path="/wizard/:teamFundId/:step?" render={(props) => 
                <WizardApp teamFundId={props.match.params.teamFundId} step={props.match.params.step} />
              } />
              <Route path="/summary/:postId" render={(props) => 
                <RegSummaryApp postId={props.match.params.postId} />
              } />
              <Route path="/overview/:teamFundId" render={(props) => 
                <OverviewApp teamFundId={props.match.params.teamFundId} />
              } />
              <Route path="/pay-page/:teamFundId/pay-info/:participantid?" render={(props) => 
                <PayApp teamFundId={props.match.params.teamFundId} contactId={props.match.params.participantid} secretId={new URLSearchParams(props.location.search).get("secret")} />
              } />
              <Route render={() =>  <>
                <h1>Not found</h1>
                {DEV && <>
                  Här är smidiga länkar för oss utvecklare:
                  <div>
                    Antons 123: <a href="/wizard/29109787/">Wizard</a> | <a href="/overview/29109787/">Overview</a> <br/>
                    Joakimsd: <a href="/wizard/29109522/">Wizard</a> | <a href="/overview/29109522/">Overview</a>
                  </div>
                </>}
              </>} />
            </Switch>
          </Router>
        </AxiosProvider>
      </Suspense>
    </LangProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
