import React, {useState} from 'react';
import ParticipantContact from './ParticipantContact';
import ConfirmDialog from '../../ConfirmDialog';
import {useTranslations} from 'cupman-utils';
import loadingCircle from '../../../../img/loading-circle-white.svg';

const newContact = () => ({
  firstName: '', 
  lastName: '', 
  phone: '', 
  email: ''
});

export default function ParticipantEditor({participantLabel, participant:initialParticipant, isNew, onSave, onDelete, isSaving, isDeleting}) {
  const T = useTranslations("TeamCollect");
  const [participant, setParticipant] = useState(initialParticipant);
  const [pcToRemove, setPcToRemove] = useState(undefined);
  const addParticipant = (keyValuePair) => setParticipant({
    ...participant,
    ...keyValuePair
  });


  return <>
    <h2><i className="fas fa-user"></i> {participantLabel}</h2>
    <p>{T('Alla fält måste vara ifyllda innan du kan spara. Det måste finnas minst en kontaktperson.')}</p>
        
    <div id="new-participant">
      <div className="row">
        <div className="col s-5">
          <label htmlFor="first-name-participant">{T('Förnamn')}</label>
          <input disabled={isSaving} type="text" value={participant.firstName} id="first-name-participant" 
              onChange={e => addParticipant({
                firstName: e.target.value
              })} />
        </div>
        <div className="col s-5">
          <label htmlFor="last-name-participant">{T('Efternamn')}</label>
          <input disabled={isSaving} type="text" value={participant.lastName} id="last-name-participant"
          onChange={e => addParticipant({
            lastName: e.target.value
          })} />
        </div>
      </div>

      <div className="participant-contacts-edit">
        <h3><i className="fas fa-address-book"></i> {T('Kontaktpersoner')}</h3>
        {participant.contacts.map((_pc, i) => 
          <div key={participant.id + i}>
            <ParticipantContact isSaving={isSaving} contact={_pc} index={participant.contacts.length} isNew={isNew} 
                onRemove={() => {
                  if(_pc.firstName !== '') {
                    setPcToRemove(_pc)
                  } else {
                    addParticipant({
                      contacts: participant.contacts.filter(_c => _c!==_pc)
                    })
                  }
                }} 
                onContactChange={updatedContact => {addParticipant({
                  contacts: participant.contacts.map(_c => _c.id===_pc.id ? updatedContact : _c )
                })
            }} />
          </div>
        )}

        <ConfirmDialog visible={pcToRemove !== undefined} setVisibility={() => setPcToRemove(undefined)} functionToRun={() => {
            setPcToRemove(undefined);
            addParticipant({
            contacts: participant.contacts.filter(_c => _c!==pcToRemove)
          });
        }} sendTxt={T('Ta bort')} msg={T('Vill du verkligen ta bort kontaktperson: X Y?', pcToRemove ? pcToRemove.firstName:'', pcToRemove ?pcToRemove.lastName:'')} />


        <div className="row add-contact">
          <div className="col s-10">
            <button type="button" disabled={isSaving} className="btn hollow-secondary" onClick={() => addParticipant({
              contacts: [...participant.contacts, newContact()]
            })}>{T('Lägg till extra kontaktperson')} <i className="fas fa-user-plus"></i></button>
          </div>
        </div>
        <div className="row">
          <div className="col s-10">

            <button className="right btn save" type="button" 
              disabled={!( participant.firstName && participant.lastName && participant.contacts.every(c => c.firstName && c.lastName && /.+@.+\..+/.test(c.email) && /^[^A-Za-z]+$/.test(c.phone)) ) || (isDeleting || isSaving)}
              onClick={() => {
                onSave(participant);
              }}
            >{T('Spara deltagare')} {
              isSaving ? 
              <> 
                <span className="sr-only">{T('Autosparar, vänta')}</span>
                <img src={loadingCircle} alt="" className="loading-circle" />
              </> : <i className="fas fa-check"></i>
              }</button>

            {!isNew && <button disabled={isDeleting || isSaving} type="button" className="right btn delete" onClick={onDelete}>{T('Radera deltagare')} {
              isDeleting ? 
              <> 
                <span className="sr-only">{T('Autosparar, vänta')}</span>
                <img src={loadingCircle} alt="" className="loading-circle" />
              </> : <i className="fas fa-trash-alt"></i>
              }</button>}
          </div>
        </div>
      </div>

    </div>
  </>
}