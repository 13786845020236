import React from 'react';
import {useTranslations} from 'cupman-utils';

export default function ProgressBarPayed({totalCount, paidCount}) {
  const percentagePayed = paidCount / totalCount * 100;
  const T = useTranslations("TeamCollect");

  return (
    <>
    <h2 className="progress-header">{T('X/Y deltagare har betalat', ''+paidCount, ''+totalCount)}:</h2>
    <div className="progress-bar_frame">
      <div className="progress-bar_inner-frame">
        <div className="lines-wrapper">
          {Array.from({length:totalCount}).map((x, i) => 
            <div className="line" key={i}></div>
          )}
        </div>
        <div className="progress-bar" style={{'width' : percentagePayed+'%'}}>
        </div>
      </div>
    </div>
    </>
  )
}