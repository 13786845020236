import React from 'react';
import {participantProductCost, usedTeamCash, formatCurrency} from '../../utils';
import {useTranslations} from 'cupman-utils';


export default function DistributedSum({participants, products, teamCash}) {
  const T = useTranslations("TeamCollect");

  let allocatedSum = 0;

  for(const participant of participants) {
    for(const product of products) {
      allocatedSum += participantProductCost(participant, product);
    }
  }

  return (
    <>
      <h2>{T('Fördelad summa')}</h2>
      <fieldset>
      <legend className="sr-only">{T('Produkter')}</legend>
        <table>
          <tbody>
            <tr>
              <td className="bold">{T('Fördelad summa')}:</td>
              <td>{formatCurrency(allocatedSum)}</td>
            </tr>
            {usedTeamCash(teamCash, participants, products) > 0 && <tr>
              <td className="bold">{T('Använd lagkassa')}:</td>
              <td className="red">- {formatCurrency(usedTeamCash(teamCash, participants, products))}</td>
            </tr>}
            <tr>
              <td colSpan="2">
                <hr />
              </td>
            </tr>
            <tr className="summary-sum">
              <td>{T('Att driva in')}:</td>
              <td>{formatCurrency(allocatedSum - usedTeamCash(teamCash, participants, products))}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </>
  )
}