import React from 'react';
import {useTranslations} from 'cupman-utils';

export default function HowToSpread({onSpreadEvenChange, spreadEven}) {
  const T = useTranslations("TeamCollect");

  return (
    <>
      <fieldset className="allocate-participant">
          <legend><h3>3. {T('Hur ska kostnaderna fördelas på deltagarna')}?</h3></legend>
          <div className="input-container">
            <input type="radio" id="allocate-participant-01" checked={!!spreadEven} name="allocate" onChange={() => {
              onSpreadEvenChange(true);
            }} />
            <label htmlFor="allocate-participant-01">{T('Alla ska betala lika mycket')}</label>
          </div>
          <div className="input-container">
            <input type="radio" id="allocate-participant-02" checked={spreadEven !== undefined ? !spreadEven : false} name="allocate" onChange={() => {
              onSpreadEvenChange(false);
            }} />
            <label htmlFor="allocate-participant-02">{T('Jag vill själv fördela kostnaderna på deltagarna')}</label>
          </div>
        </fieldset>
    </>
  )
}