import React from 'react';
import {useTranslations} from 'cupman-utils';

export default function Betabanner({uiData, onUiChange}) {
  const T = useTranslations("TeamCollect");

  return (
    <>{ false && <div id="beta-banner" className="row gutter dark beta-banner">
      <div className="beta-banner-content">
        <div className="col beta-banner__info">
        <p dangerouslySetInnerHTML={{__html: T('Du använder vårt nya verktyg för laginsamling. Om du upptäcker något fel eller funktion du saknar, kontakta oss gärna.')}} />
        </div>
        <div className="col">
          <div className="beta-banner__btns">
            <button className="btn light-primary" onClick={() => window.Intercom('showNewMessage')}>{T('Skicka feedback')} <i className="far fa-angle-right"></i></button>
            <button className="btn light-secondary" onClick={() => onUiChange({
              ...uiData,
              betaBanner : false
            })}>{T('Stäng')} <i className="fas fa-times-circle"></i></button>
          </div>
        </div>
      </div>
    </div>}</>
  )
}