import React, {useState, useContext} from 'react';
import ModalTeamCash from './ModalTeamCash';
import EvenSpreadParticipant from './EvenSpreadParticipant';
import UnevenSpreadParticipants from './UnevenSpreadParticipants';
import loadingCircle from '../../../../img/loading-circle-white.svg';
import DynamicSum from '../../DynamicSum';
  import ProductsTable from '../../DynamicSum/ProductsTable';
  import ProductRow from '../../DynamicSum/ProductRow';
  import DistributedSum from '../../DynamicSum/DistributedSum';
  import SumTeamCash from '../../DynamicSum/SumTeamCash';
import { divideMoney, totalUsedSum, usedTeamCash, myScrollTop, serviceFee } from '../../../utils';
import { useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import {useTranslations} from 'cupman-utils';
import {SaveContext} from '../../../SaveContext';
import {formatCurrency} from '../../../utils';

export default function Content ({uiData, onUiChange, data, onDataChange}) {
  const T = useTranslations("TeamCollect");
  const save = useContext(SaveContext);
  const hasServiceFee = !!data.participants.find(p => { return serviceFee(p) > 0});
  const tosAcceptText = T('En serviceavgift läggs på alla deltagare som har något att betala. Acceptera för att gå vidare');
  const history = useHistory();
  //console.log(save);

  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {

    if(data.spreadEven) {

      onDataChange({
        ...data,
        participants: data.participants.map(participant => {
          var products = [];

          for(const product of data.products) {
            if(product.checked) {
              products.push({
                id: product.id, 
                fraction: [1, data.participants.length],
                moneyIds: product.moneyIds})
            }
          }

          return {
            ...participant,
            products: products
          }
        }),
        products: data.products.map(p => {
            return {...p,
            spreadSum: true}
        })
      })
    }
  }, []); //eslint-disable-line




  const isValid = (spreadEven, totalUsedSum, totalUsedTeamCash) => {

    if (spreadEven && (data.tosAcceptDate || !hasServiceFee)) {
      return true;
    }

    if((totalUsedSum - totalUsedTeamCash) > 0 && (data.tosAcceptDate || !hasServiceFee)) {
      return true;
    }

    return false;
  }

  const dataPerParticipant = () => {
    let sumProducts = {
      total: 0,
      products: []
    };
    
    for(let i = 0; i < data.products.length; i++) {
      if(data.products[i].checked) {

        const sum = divideMoney(data.products[i].amount, data.participants.length);

        sumProducts.products.push({
          name: data.products[i].name, 
          sum: sum
        });

        sumProducts.total += sum;
      }
    }

    if(data.teamCash && data.teamCash.amount > 0) {
      sumProducts.teamCash = divideMoney(data.teamCash.amount, data.participants.length);
      sumProducts.total -= sumProducts.teamCash;
    }

    return sumProducts;
  }

  return (
    <main className="content-wrapper">
      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          <h1>{data.name}</h1>
        </div>
      </div>
      
        <div className="row gutter">

          {/* LEFT COLUMN */}
          <div className="col l-5 summary_info">
            <h2>{T('Steg 3: Produkter')}</h2>
            {data.spreadEven && <p>{T('Du har valt att dela summan jämnt mellan deltagarna.')}</p>}

            {!data.spreadEven && 
            <>
              <p>{T('Du har valt att fördela produkterna så att deltagarna kan stå för olika kostnader. Nedan kan du välja vem som ska betala för vad.')}</p>
            </>}

            <h3>{T('Lagkassa')}</h3>
            <p>{T('Om du förfogar över en lagkassa som du vill använda för att betala en del av summan, så kan du lägga in den här. Lagkassan fördelas sedan jämnt på varje deltagare')}.</p>
            <p><i className="fas fa-exclamation-circle"></i> {T('Du som lagledare betalar in lagkassan senare')}.</p>

            <button className="btn dark-primary" onClick={() => setIsOpen(true)}>{(data.teamCash ? data.teamCash.amount : 0) > 0 ?  T('Lagkassa X',formatCurrency(data.teamCash.amount)) : T('Lägg in lagkassa')} <i className="far fa-angle-right"></i></button>

            <ModalTeamCash type="button" isOpen={isOpen} teamCash={data.teamCash ? data.teamCash.amount : 0} setIsOpen={setIsOpen} participantsLength={data.participants.length} saveTeamCash={updatedTeamCash => {
              onDataChange({
                ...data,
                teamCash: {
                  ...data.teamCash,
                  amount: updatedTeamCash
                }
              })
            }} />
          </div>
          {/* RIGHT COLUMN */}
          
          <div className="col l-5 products-column">
            <div>
              <DynamicSum status={3}>
                <ProductsTable products={data.products} teamCashText={false}>
                  {data.products.map((product, i) => <ProductRow product={product} checkBoxes={false} i={i} key={i} 
                    onProductChange={newProduct => onDataChange({
                      ...data,
                      products: data.products.map(_product => _product===product ? newProduct : _product)
                    })}
                  />)}
                </ProductsTable>
                <SumTeamCash teamCash={data.teamCash ? data.teamCash.amount : 0} />

                {(data.spreadEven === false || data.spreadEven === null)  && <DistributedSum participants={data.participants} products={data.products} teamCash={data.teamCash ? data.teamCash.amount : 0} />}
              </DynamicSum>
            </div>
          </div>
          
        </div>
      
      {/* SPREAD UNEVEN */}
      {!data.spreadEven && 
        <UnevenSpreadParticipants products={data.products} teamCash={data.teamCash ? data.teamCash.amount : 0} participants={data.participants}
          onDataChange={(updatedParticipants, updatedProducts) =>
            onDataChange({
              ...data,
              participants: updatedParticipants,
              products: updatedProducts
            })
          } />
        
      }
      
      {/* SPREAD EVEN */}
      {data.spreadEven && 
        <EvenSpreadParticipant randomParticipant={data.participants[0]} dataPerParticipant={dataPerParticipant()} participantCount={data.participants.length} />
      }


      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          {hasServiceFee && <div className="input-container accept">
            <input type="checkbox" id="accept" checked={!!data.tosAcceptDate} onChange={e => {
              if (e.target.checked) {
                onDataChange({
                  ...data,
                  tosAcceptDate: Date.now(),
                  tosAcceptText: tosAcceptText
                })
              } else {
                onDataChange({
                  ...data,
                  tosAcceptDate: false
                })
              }
            }} />
            <label htmlFor={"accept"}>{tosAcceptText}.</label>
          </div>}
        </div>
        <div className="col l-10 nav-btns">
          <button onClick={() => history.push(`/wizard/${data.id}/participants`)} className="btn delete previous"><i className="far fa-angle-left"></i> {T('Deltagare')}</button>

          <button onClick={() => {
            setIsSaving(true);
            save().then(
              ()=> {
                history.push(`/wizard/${data.id}/publish`);
                myScrollTop();
              });
          }} className="btn dark-primary next" disabled={!isValid(data.spreadEven, totalUsedSum(data.participants, data.products), usedTeamCash(data.teamCash ? data.teamCash.amount : 0, data.participants, data.products))}>{T('Förhandsgranska')} {
            isSaving ? 
            <> 
              <span className="sr-only">{T('Autosparar, vänta')}</span>
              <img src={loadingCircle} alt="" className="loading-circle" />
            </> : <i className="far fa-angle-right"></i>
            }
            </button>
        </div>
      </div>
    </main>
  )
}