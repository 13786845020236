import React, {useState, useContext} from 'react';
import {divideMoney, formatCurrency} from '../../../utils';
import {useTranslations} from 'cupman-utils';
import {SaveContext} from '../../../SaveContext';
import loadingCircle from '../../../../img/loading-circle-white.svg';

export default function SpreadSum ({participants, product, setIsOpen, onSave, style}) {
  const T = useTranslations("TeamCollect");
  const save = useContext(SaveContext);
  const [isSaving,setIsSaving] = useState(false);

  // FUNCTIONS
  const participantProductState = (participants, product) => {
    return participants.reduce((map, participant)=>{
        map[participant.id]=participant.products.some(p=>
          p.id===product.id && p.fraction[0] > 0); 
      return map
    },{});
  }

  // STATE
  const [spreadSumCount, setSpreadSumCount] = useState(participantProductState(participants, product));


  const moneySplit = divideMoney(product.amount, Object.values(spreadSumCount).filter(v => v).length);
  
  const participantsMoney = (moneySplit, showThisId) => {
    return showThisId && moneySplit !== Infinity ? `${formatCurrency(moneySplit)}` : '';
  }

  // RENDER
  return (
    <div className="participants-product" style={style}>
      <h4>{T('Välj vilka som ska dela på summan av')} {product.name}:</h4>
      {participants.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(_p =>
        <div className="row" key={_p.id}>
          <div className="col i-5">
            <input type="checkbox" id={"participant-" +_p.id} checked={spreadSumCount[_p.id]} onChange={e => {
              setSpreadSumCount({
                ...spreadSumCount,
                [_p.id]: e.target.checked
              })
            }} />
            <label htmlFor={"participant-" +_p.id}>{_p.firstName} {_p.lastName}</label>
          </div>
          <div className="col i-5 right italic">
            <span className="bold">{participantsMoney(moneySplit, spreadSumCount[_p.id])}</span>
          </div>
        </div>
      )}
      <button className="btn save" onClick={() => {
          onSave(spreadSumCount); 
          setIsSaving(true);
          save().then(()=>{
            setIsSaving(false);
            setIsOpen(false);
          });}
      }>{T('Spara')}{
        isSaving ? 
        <> 
          <span className="sr-only">{T('Autosparar, vänta')}</span>
          <img src={loadingCircle} alt="" className="loading-circle" />
        </> : <i className="far fa-angle-right"></i>
        }</button>
    </div>
  )
}