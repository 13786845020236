import React, { useState, useContext, useEffect } from 'react';
import Axios from 'axios';
import { useTranslations } from 'cupman-utils';
import Header from './js/components/Overview/Header';
import Footer from './js/components/Overview/Footer';
import fetchJsonp from "fetch-jsonp";

let AxiosContext = React.createContext();

export function useAxios() {
    return useContext(AxiosContext);
}

export class AxiosProvider extends React.Component {
    constructor() {
        super();
        this.state = {}
        
        var thiz = this;
        this.value = {
            get: function(a,b,c,d) {
                return Axios.get.apply(Axios, arguments).catch(thiz.catcher.bind(thiz))
            },
            put: function() {
                return Axios.put.apply(Axios, arguments).catch(thiz.catcher)
            },
            post: function() {
                return Axios.post.apply(Axios, arguments).catch(thiz.catcher)
            },
            delete: function() {
                return Axios.delete.apply(Axios, arguments).catch(thiz.catcher)
            }
        }
    }
    
    catcher(error) {
        if (error.response) {
            if (error.response.status === 401) {
                if (error.response.data.redirectTo) {
                    const errorUrl = `${error.response.data.redirectTo}?redirectTo=${window.location.href}`;
                    window.location = `https://login.cupmanager.net/service/login/sso?domain=${window.location.host}&successUrl=${window.location.href}&errorUrl=${errorUrl}`
                    //
                }
            } else if (error.response.status === 403) {
                if (error.response.data.redirectTo) {
                    this.setState({
                        error
                    });
                }
            }
        }
        throw error;
    }
    
    render() {
        return <AxiosContext.Provider value={this.value}>
            {this.state.error
                ? <LoginBox error={this.state.error} onLogin={() => this.setState({})}/>
                : this.props.children}
        </AxiosContext.Provider>
    }
}



// Returns undefined | true | false
// function useSSO() {
//     const [status, setStatus] = useState();
//     useEffect(() => {
//       fetchJsonp("https://login.cupmanager.net/service/SingleSignOnService?jsonp=true&domain="+window.location.host)
//         .then(r => {
//           console.log({r})
//           if(r.ok || r.type === "opaque") {
//             return r.json();
//           } else {
//             throw r
//           }
//         }).then(data  => {
//             if (data.status === 1) {
//                 setStatus(false);
//             } else {
//                 setStatus(true);
//             }
//         })
//     }, []);
//     return status;
// }

function LoginBox({error, onLogin}) {
    const T = useTranslations("TeamCollect");

    function logout() {
        return fetch("https://login.cupmanager.net/service/LogoutService?domain="+window.location.host, {
          credentials: "include",
          mode: "no-cors",
          method: "POST"
      });
    }

    return <div>
        <Header/>
        <main className="content-wrapper">
          <div className="row">
            <div className="login-form col l-4">
              <div>
                  <h1>{T('Ingen behörighet')}</h1>
                  <p>{T('Du är inloggad men har inte behörighet till denna insamling')}</p>
                  <button className="btn save" onClick={() => {
                      logout().then(() => {
                          window.location = `${error.response.data.redirectTo}?redirectTo=${window.location.href}`;
                      })
                  }}>
                    {T('Logga ut och in igen')} <i class="fas fa-sign-in"></i>
                  </button>
              </div>
            </div>
          </div>
        </main>
        <Footer />
    </div>
}