import React from 'react';
import ProductButton from './ProductButton';
import ParticipantEach from './ParticipantEach';
import {divideMoney} from '../../../utils';
import {useTranslations} from 'cupman-utils';

export default function UnevenSpreadParticipants ({products, participants, onDataChange, teamCash}) {

  const T = useTranslations("TeamCollect");
  const participantTeamCash = divideMoney(teamCash, participants.length);

  return (
    <>
      <div className="row gutter">
        <div className="col l-10">
          <div className="participants_wrapper">
            <h2>{T('Vem ska betala för varje produkt?')}</h2>

            <div className="products-row">
              {products.filter((_p) => _p.checked).map(_p => 
                <ProductButton key={_p.id} product={_p} 
                      participants={participants} 
                      onDataChange = {(updatedParticipants, updatedProduct) => onDataChange(updatedParticipants, products.map(p => p.id === _p.id ? updatedProduct : p))}/>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="row gutter">
        <div className="col l-10">
          <div className="participants_wrapper">
            <h2><i className="fas fa-users" aria-hidden="true"></i> {T('Deltagare')}</h2>

            {participants.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(participant => 
              <ParticipantEach key={participant.id} participant={participant} products={products} participantTeamCash={participantTeamCash} />
            )}
          </div>
        </div>
      </div>
    </>
  )
}