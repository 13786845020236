import React, {useState, useContext} from 'react';
import {SaveContext} from '../../SaveContext';
import {useTranslations} from 'cupman-utils';
import loadingCircle from '../../../img/loading-circle-white.svg';
import Modal from 'react-modal';

var classNames = require('classnames');



export default function LeaderActions ({id, isStopOpen, setModalStopOpen, isCancelOpen, setModalCancelOpen}) {
  const T = useTranslations("TeamCollect");
  const save = useContext(SaveContext);
  
  
  const [sureCancel, setSureCancel] = useState(false);
  const [sureFinish, setSureFinish] = useState(false);
  
  const [isSaving, setIsSending] = useState(false);
  const [sendMsg, setSendMsg] = useState({
    msgText: null,
    msgStatus: null
  })


  const closeModal = () => {
    setModalStopOpen(false);
    setModalCancelOpen(false);
  }

  return (
    <>
      {/* STOP MODAL */}
      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isStopOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="Avsluta insamling">

        <h3>{T('Är du säker')}?</h3>
        
        <p>{T('Om du fortsätter kommer insamlade pengar kommer att överföras till turneringen.')} {T('De delar av insamlingen som inte betalats in kommer behöva betalas in på annat sätt till turneringen.')}</p>
        <div className="input-container accept" style={{margin: '1rem 0', float: 'none'}}>
          <input type="checkbox" id="accept" checked={sureFinish} onChange={e => setSureFinish(e.target.checked)} />
          <label htmlFor={"accept"}>{T('Jag förstår att detta inte går att ångra.')}</label>
        </div>
        <div className="btns">
          <button disabled={!sureFinish} className="btn dark-primary" onClick={() => {
            setIsSending(true);

            save.promiseCall(true, `${save.actionUrl}${id}/settle`, 'post').then(() =>{

              setIsSending(false);
              setSendMsg({
                msgText: T('Insamlingen är nu avslutad.'),
                msgStatus: true
              });

            }).catch(() => {
              setSendMsg({
                msgText: T('Vi lyckades inte avsluta insamlingen. Försök igen.'),
                msgStatus: false
              });
              setIsSending(false);

            });
          }}>
          {T('Ja, jag är säker, avsluta nu')} {isSaving ? 
            <> 
              <span className="sr-only">{T('Autosparar, vänta')}</span>
              <img src={loadingCircle} alt="" className="loading-circle" />
            </> : <i className="fas fa-times-circle"></i>
            }
          </button>
          <div className={classNames('send-status', {sent: sendMsg.msgStatus, failed: sendMsg.msgStatus === false})}>
            {sendMsg.msgStatus === false && <i className="fas fa-exclamation-triangle"></i>} {sendMsg.msgStatus && <i className="fas fa-check-circle"></i>} {sendMsg.msgText}
          </div>
        </div>

        {!sendMsg.msgStatus && <button onClick={() => setModalStopOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>}
      </Modal>
      {/* / STOP MODAL */}

      {/* CANCEL MODAL */}
      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isCancelOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel="Avsluta insamling">

        <h3>{T('Är du säker')}?</h3>
        <p>{T('Om du avbryter nu så kan du inte ångra det sen. Alla pengar som samlats in till insamlingen kommer kommer att återbetalas.')}</p>
        <div className="input-container accept" style={{margin: '1rem 0', float: 'none'}}>
          <input type="checkbox" id="accept" checked={sureCancel} onChange={e => setSureCancel(e.target.checked)} />
          <label htmlFor={"accept"}>{T('Jag förstår att detta inte går att ångra.')}</label>
        </div>

        <div className="btns">
          <button disabled={!sureCancel} className="btn dark-primary" onClick={() => {
            setIsSending(true);

            save.promiseCall(true, `${save.actionUrl}${id}/cancel`, 'post').then(() =>{

              setIsSending(false);

              setSendMsg({
                msgText: T('Insamlingen är nu avslutad.'),
                msgStatus: true
              });

              closeModal();

            }).catch(() => {
              setSendMsg({
                msgText: T('Vi lyckades inte avsluta insamlingen. Försök igen.'),
                msgStatus: false
              });
              setIsSending(false);
            })

          }}>
            {T('Ja, jag är säker, avbryt insamlingen nu')} {isSaving ? 
              <> 
                <span className="sr-only">{T('Autosparar, vänta')}</span>
                <img src={loadingCircle} alt="" className="loading-circle" />
              </> : <i className="fas fa-times-circle"></i>
              }
          </button>
          <div className={classNames('send-status', {sent: sendMsg.msgStatus, failed: sendMsg.msgStatus === false})}>
            {sendMsg.msgStatus === false && <i className="fas fa-exclamation-triangle"></i>} {sendMsg.msgStatus && <i className="fas fa-check-circle"></i>} {sendMsg.msgText}
          </div>
        </div>

        {!sendMsg.msgStatus && <button onClick={() => setModalCancelOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>}
      </Modal>
      {/* / CANCEL MODAL */}
    </>
  )
}