import React from 'react';
import {formatCurrency} from '../../utils';

export default function ProductRow({product, checkBoxes, i, onProductChange}) {
  const mystyle = {
    style1: { 
      textDecoration: "none",
      color: 'var(--color-black)'
    },
    style2: {
      textDecoration: "line-through",
      color: 'var(--color-red-1)'
  }
  };

  return (
    <tr>
      <td>
        {checkBoxes && <div className="input-container">
          <input type="checkbox" id={"product-0" +i} checked={product.checked} onChange={e => onProductChange({
            ...product,
            checked: e.target.checked
          })} />
          <label htmlFor={"product-0" +i} style={product.checked ? mystyle.style1 : mystyle.style2}>{product.name}</label>
        </div>}

        {!checkBoxes && <span className="fake-label" style={product.checked ? mystyle.style1 : mystyle.style2}>{product.name}</span>}
      </td>
      <td style={product.checked ? mystyle.style1 : mystyle.style2}>{product.count}</td>
      <td style={product.checked ? mystyle.style1 : mystyle.style2}>{formatCurrency(product.amount)}</td>
    </tr>
  )
}