import React, {useState} from 'react';
import ParticipantContactForm from './ParticipantContactForm';
import Modal from 'react-modal';
import {useTranslations} from 'cupman-utils';

export default function ParticipantContact({participant, onSave, index, id}) {
  const T = useTranslations("TeamCollect");

  const [isOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  }

  const newContact = () => ({
    id: null,
    firstName: '', 
    lastName: '', 
    phone: '', 
    email: '',
    economy: {
      status: 'unpaid'
    }
  });

  return (
    <>
      {participant.status !== 'paid' && <button className="btn dark-secondary" onClick={() => setModalOpen(true)}>
        {T('Lägg till kontakt')} <i className="far fa-plus" style={{'marginLeft' : '.3rem'}}></i>
      </button>}

      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}>
        <h2>{T('Ny kontakt')}</h2>

        <p>{T('När du lägger till en ny kontakt så skickas ett utskick av inbjudan automatiskt när du sparar.')}</p>

        <ParticipantContactForm contact={newContact} onSave={onSave} participant={participant} setModalOpen={setModalOpen} index={index} id={id}/>

        <button onClick={() => setModalOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>
      </Modal>
    </>
  )
}
