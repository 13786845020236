import React, {useState} from 'react';

import AnimateHeight from 'react-animate-height';
import {animationDuration} from '../../utils';

var classNames = require('classnames');

export default function GroupParticipantsByListStatus({big, icon, title, children}) {
  // TOGGLE ACCORDION
  const [accHeight, setAccHeight] = useState(0);
  const toggleAcc = () => setAccHeight(accHeight === 0 ? 'auto' : 0);
  // / TOGGLE ACCORDION

  console.log(big);

  return (
    <>


      {/* BIG */}
      {big &&
      <>
        <h2>
          <i className={icon}></i> {title}
        </h2>
        {children}
      </>}
      {/* / BIG */}



      {/* SMALL */}
      {!big && 
      <div className={classNames('paid-wrapper', {active: accHeight !== 0})}>
        <h2 className="accordion-paid">
          <button onClick={() => toggleAcc()}>
            <i className={icon}></i> {title}
          </button>
        </h2>
        <AnimateHeight
          duration={ animationDuration }
          height={ accHeight }
        >
          <div className="paid-inner-wrapper">
            {children}
          </div>
        </AnimateHeight>
      </div>}
      {/* / SMALL */}

      
    </>
  )
}