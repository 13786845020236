import React,{useState} from 'react';

import moment from "moment";
import {useTranslations} from 'cupman-utils';
import {formatCurrency} from '../../utils';
import DynamicSum from '../DynamicSum';
  import ProductsTable from '../DynamicSum/ProductsTable';
  import ProductRow from '../DynamicSum/ProductRow';
  import TableTrTeamCash from './TableTrTeamCash';
import LeftToPay from './LeftToPay';
import Participant from './Participant';
import ProgressBarPaid from './ProgressBarPaid';
import LeaderActions from './LeaderActions';
import TeamCash from './TeamCash';
import Group from './Group';

const DEV =  false && process.env.NODE_ENV === 'development';


export default function Content ({data, onDataChange, id}) {
  const T = useTranslations("TeamCollect");
  const [isStopOpen, setModalStopOpen] = useState(false);
  const [isCancelOpen, setModalCancelOpen] = useState(false);
  
  
  const participantsByListStatus = (listStatus) => {
    return data.participants.filter(participant => participant.economy.listStatus === listStatus);
  }
  const collecting = participantsByListStatus('collecting');
  const refunded = participantsByListStatus('refunded');
  const cancelled = participantsByListStatus('cancelled');
  const paid = participantsByListStatus('paid');
  const participantsAllPaid = data.participants.filter(p => p.economy.listStatus === 'collecting' || p.economy.listStatus === 'anonymous').length <= 0 ? true : false;
  console.log(participantsAllPaid);

  const participantProps = {
    products: data.products, 
    id: data.id
  }
  const daysLeft = () => {
    const given = moment(data.tournament.dates.end, "YYYY-MM-DD");
    const current = moment().startOf('days');

    if(given.diff(current, 'days') > 0) {
      return given.diff(current, 'days');
    } else {
      return '0';
    }
  }

  const teamCashPayed = !data.teamCash || data.teamCash.status === 'paid';
  const teamCashAmount = data.teamCash ? data.teamCash.amount : 0;

  return (
    <main className="content-wrapper overview" style={{maxWidth: '900px', margin: 'auto'}}>
      {/* 
      =======================================================
      debug btns 
      =======================================================
      */}
      {DEV &&
      <div className="row gutter">
        <div className="col l-10 debugg">
          <button onClick={() => onDataChange({
            ...data,
            tournament: {
              ...data.tournament,
              dates: {
                  ...data.tournament.dates,
                  end: '2021-01-15'
              }}

          })}>Later date</button>

          <button onClick={() => onDataChange({
            ...data,
            teamCash: {
              ...data.teamCash,
              status: 'paid',
              date: '2020-10-10'}

          })}>Teamcash paid</button>

          <button onClick={() => onDataChange({
            ...data,
            participants: data.participants.map(p => ({
              ...p,
              economy: {
                ...p.economy,
                listStatus: 'paid',
                status: 'paid'
              }
            }))
          })}>Set paid on participants</button>

          <button onClick={() => onDataChange({
            ...data,
            teamCash: {
              ...data.teamCash,
              status: 'payLater'}

          })}>Teamcash klarna</button><br/>

          <button onClick={() => onDataChange({
            ...data,
            status: 'collecting',
            participants: data.participants.map(p => ({
              ...p,
              economy: {
                ...p.economy,
                listStatus: 'collecting',
                status: 'sent'
              }
            }))
          })}>Status collecting</button>

          <button onClick={() => onDataChange({
            ...data,
            status: 'complete'

          })}>Status complete</button>

          <button onClick={() => onDataChange({
            ...data,
            status: 'settled',
            participants: data.participants.map(p => ({
              ...p,
              economy: {
                ...p.economy,
                listStatus: 'paid',
                status: 'paid'
              }
            }))
          })}>Status settled</button>

          <button onClick={() => onDataChange({
            ...data,
            status: 'cancelled',
            participants: data.participants.map(p => ({
              ...p,
              economy: {
                ...p.economy,
                listStatus: 'cancelled',
                status: 'cancelled'
              }
            }))
          })}>Status cancelled</button>

            <button onClick={() => onDataChange({
            ...data,
            status: 'refunded',
            participants: data.participants.map(p => ({
              ...p,
              economy: {
                ...p.economy,
                listStatus: 'refunded',
                status: 'refunded',
              }
            }))
          })}>Status refunded</button><br/>

          <button onClick={() => onDataChange({
            ...data,
            totalCollected: 300
          })}>+ Total collected</button>

          <button onClick={() => onDataChange({
            ...data,
            toPay: 300
          })}>+ ToPay</button>

          <button onClick={() => onDataChange({
            ...data,
            toPay: 600
          })}>++ ToPay</button><br/>
          
          <button onClick={() => onDataChange({
            ...data,
            anonymous: true,
            anonymousCount: 12,
            participants: data.participants.map(p => ({
              ...p,
              economy: {
                ...p.economy,
                listStatus: 'paid',
                status: 'paid'
              }
            }))
          })}>Anonymous</button>
        </div>
      </div>
      }

      {/* 
      =======================================================
      // debug btns 
      =======================================================
      */}

      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          <h1>{data.name} <span style={{whiteSpace: "nowrap"}}>{data.status !== 'settled' && data.status !== 'cancelled' && <>({T('X dagar kvar',daysLeft())})</>}</span></h1>
         
          
          {data.status !== 'cancelled' && <p className="sum-paid">{T('X betalat av Y',formatCurrency(data.amountCollected),formatCurrency(data.amount))}</p>}
          {data.status === 'cancelled' && 
            
              <div className="">
                <div className="refunded-info">
                  <h2><span className="fa-stack fa-1x">
                  <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
                  <i className="fas fa-undo-alt fa-stack-1x fa-inverse" style={{color: 'var(--color-orange-1)'}}></i>
                  </span> {T('Insamlingen är avbruten.')}</h2>
                </div> 
              </div>}

          {data.status === 'completed' && <div className="complete-info">
            <h2><span className="fa-stack fa-1x">
              <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
              <i className="fas fa-hourglass-end fa-stack-1x fa-inverse" style={{color: 'var(--color-orange-1)'}}></i>
            </span> {T('Tiden har gått ut!')}</h2>
            <h3>{T('Allt är inte betalat. Du kan göra något av följande:')}</h3>
            <ul>
              {!participantsAllPaid && <li>{T('Avvakta inbetalningar. Påminn de som inte betalat för att driva på.')}</li>}
              {!teamCashPayed && <li>{T('Betala in lagkassan.')}</li>}
              {(!participantsAllPaid || !teamCashPayed) && <li>{T('Slutföra insamlingen, trots att allt inte är betalt. Inbetalade pengar betalas ut till turneringen.')}</li>}
              {(participantsAllPaid && teamCashPayed) && <li>{T('Slutföra insamlingen. Inbetalade pengar betalas ut till turneringen.')}</li>}
              <li>{T('Avbryta insamlingen. Inbetalade pengar återbetalas, dock ej serviceavgifter.')}</li>
             
            
            </ul>

            {!data.teamCash && 
                <div className="acc-container">
                  <a className={"btn dark-primary overview_btn"} target="_blank" rel="noopener noreferrer" href={data.team.editUrl}> {T('Gå till anmälan')} <i className="fas fa-external-link"></i>
                  </a>
                  
                  {data.amountCollected > 0 && <button className={"btn dark-secondary overview_btn"} onClick={() => setModalStopOpen(true)}> {T('Slutför insamling nu')} <i className="far fa-check"></i>
                  </button>}
                  
                  <button className={"btn overview_btn dark-secondary cancel"} onClick={() => setModalCancelOpen(true)}> {T('Avbryt insamling nu')} <i className="fas fa-times-circle"></i>
                  </button>
                </div>
            }
            
          </div>}

          


          {(data.status === 'collecting' || data.status === 'completed') && 
            <div style={{marginBottom: "3em"}}>
              <ProgressBarPaid totalCount={data.participants.length} paidCount={data.participants.filter(_p => _p.economy.status === 'paid').length} />
            </div>}

          {data.status === "collecting" && data.anonymous && <>
            
            <div className="pay-link">
              <strong>{T('Betalningslänk')}</strong>:<br/>
              <p>{T('Här är betallänken som du kan dela så att deltagare kan betala')}:</p>
              <a href={data.anonymousPayUrl} target="_blank" rel="noopener noreferrer" style={{color: 'var(--color-white)'}}>{data.anonymousPayUrl}</a>
              <div style={{"clear": "both"}}></div>
              <button className="btn light-primary" onClick={() => navigator.clipboard.writeText(data.anonymousPayUrl)}>{T('Kopiera betalningslänk')} <i className="far fa-copy" style={{marginLeft: '.3rem'}}></i></button>
            </div>

          </>}


          {data.status === 'settled' && <div className="complete-info complete">
            <h2><span className="fa-stack fa-1x">
              <i className="fas fa-circle fa-stack-2x" style={{color: 'var(--color-white)'}}></i>
              <i className="fas fa-check fa-stack-1x"  style={{color: 'var(--color-green-1)'}}></i>
            </span> {T('Du har samlat in X av Y.', formatCurrency(data.amountCollected), formatCurrency(data.amount))}</h2>
            <h3>
            {T('Insamlingen är nu avslutad. De insamlade pengarna har nu betalat för de valda produkterna i X.', data.tournament.cupName)}</h3>
          </div>}

          <LeaderActions  id={id} isStopOpen={isStopOpen} setModalStopOpen={setModalStopOpen} isCancelOpen={isCancelOpen} setModalCancelOpen={setModalCancelOpen} />
         
          {data.teamCash && (data.status === 'collecting' || data.status === 'completed' || (data.status === 'cancelled' && data.teamCash.status === 'refunded'))  &&
          <TeamCash teamCashInfo={data.teamCash} team={data.team} 
              setModalStopOpen={setModalStopOpen}
              setModalCancelOpen={setModalCancelOpen}
              onChange={onDataChange} 
              endDate={data.tournament.dates.end} 
              teamFundStatus={data.status} id={id} 
              totalCollected={data.amountCollected} 
              participantsAllPaid={participantsAllPaid} 
              paymentInfo={data.paymentInfo} 
              tournament={data.tournament} />
          }

          {(data.status === 'cancelled' || data.status === 'settled') &&
              <div className="acc-container" style={{marginBottom: 20}}>
                <a className={"btn dark-primary overview_btn"} target="_blank" rel="noopener noreferrer" href={data.team.editUrl}> {T('Gå till anmälan')} <i className="fas fa-external-link"></i>
                </a>
                <a className={"btn dark-secondary overview_btn"} target="_blank" rel="noopener noreferrer" href={data.tournament.cupUrl}> {T('Gå till X', data.tournament.cupName)} <i className="fas fa-external-link"></i>
                </a>
              </div>
          }

         
        </div>
      </div>
      <div className="row gutter">
        <div className="col l-10 overview">
          {paid.length <= 0 && data.anonymous && data.status === 'collecting' && <h2><i className="far fa-exclamation-circle"></i> {T('Här kommer dina deltagare att synas när de betalat in')}</h2>}

          {/* GROUP BY STATUS */}
          {collecting.length > 0 && 
          <Group big={true} icon="far fa-exclamation-circle" title={`${T('Väntar på betalning')} (${collecting.length} ${T('st')})`}>
            {collecting.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(participant => 
              <Participant {...participantProps} 
              key={participant.id} 
              participant={participant} 
              editable={true}
              onChange={_part => {
                
                onDataChange({
                  ...data,
                  participants: data.participants.map(participant => participant.id === _part.id ? _part : participant)
                });
              }}
              />
            )}
          </Group>}

          {paid.length > 0 && 
          <Group big={data.status === 'settled' ? true : data.anonymous ? true : false} icon="far fa-check" title={`${T('Betalat')} (${paid.length + ' ' + T('av') + ' ' + data.participants.length + ' ' + T('st')})`}>
            {paid.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(participant => <Participant {...participantProps} key={participant.id} participant={participant} editable={false} />)}
          </Group>}

          {refunded.length > 0 && 
          <Group big={true} icon="fas fa-undo-alt" title={`${T('Återbetalade')} (${refunded.length} ${T('st')})`}>
            {refunded.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(participant => <Participant {...participantProps} key={participant.id} participant={participant} editable={false} />)}
          </Group>}

          {cancelled.length > 0 && 
          <Group big={true} icon="far fa-times" title={`${T('Avbrutna')} (${cancelled.length} ${T('st')})`}>
            {cancelled.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(participant => <Participant {...participantProps} key={participant.id} participant={participant} editable={false} />)}
          </Group>}
        </div>
      </div>
      <div className="row gutter">

        {/* LEFT COLUMN */}
        {data.status !== 'settled' && data.status !== 'cancelled' && <div className="col l-5 next-step">
          <h2>{T('Nästa steg')}</h2>
          <p>
            {!data.teamCash && T('Insamlingen avslutas automatiskt när alla deltagare betalat.')}
            {data.teamCash && T('Insamlingen avslutas automatiskt när alla deltagare betalat och lagkassan betalats in.')}
            {T('Du kan även välja att slutföra insamlingen i förtid om det visar sig att alla deltagare inte ska betala.')}</p>
          <p></p>
          <br></br>
          <p>{T('När tiden för insamlingen går ut måste du själv slutföra den om inte allt blivit insamlat.')}</p>
          <p>{T('De delar av insamlingen som inte betalats in kommer behöva betalas in på annat sätt.')}</p>

          
          <h3>
            {T("Due date")}: <span className="red">{data.tournament.dates.end}</span>
          </h3>
          <div className={'acc-container'}>
            <a className={"btn dark-primary overview_btn"} target="_blank" rel="noopener noreferrer" href={data.team.editUrl}> {T('Gå till anmälan')} <i className="fas fa-external-link"></i>
            </a>
            
            {data.amountCollected >0 && <button className={"btn dark-secondary overview_btn"} onClick={() => setModalStopOpen(true)}> {T('Slutför insamling nu')} <i className="far fa-check"></i>
            </button>}
            <button className={"btn dark-secondary cancel overview_btn"} onClick={() => setModalCancelOpen(true)}> {T('Avbryt insamling nu')} <i className="fas fa-times-circle"></i>
            </button>
          </div>
          
        </div>}
        {/* RIGHT COLUMN */}
        {data.status !== 'cancelled' && <div className={data.status === 'settled' ? 'col l-10' : 'col l-5'}>
          <DynamicSum publish={true}>
            <ProductsTable products={data.products} teamCashText={false} usedTeamCash={0}>
              {data.products.map((product, i) => <ProductRow product={product} checkBoxes={false} i={i} key={i} />)}
              {false && data.teamCash && <TableTrTeamCash usedTeamCash={teamCashAmount} />}
            </ProductsTable>
            <LeftToPay collected={data.amountCollected}/>
          </DynamicSum>
        </div>}
      </div>
    </main>
  )
}