import React from 'react';
// import ci_logo from '../../../img/cupinvite.svg';
import {useTranslations} from 'cupman-utils';

export default function Footer () {
  const T = useTranslations("TeamCollect");

  return (
    <footer>
      <div className="footer-wrapper">
        <div className="row gutter">
          <div className="col l-10"><h3 style={{margin: '0'}}>Support</h3></div>
        </div>
        {/*<div className="row gutter">
          <div className="col l-3">
            <h4>{T('Telefon Sverige')}:</h4>
            <a href="tel:+46-46-525-80">+46 (0)46-525 80</a>
          </div>
          <div className="col l-3">
            <h4>{T('Telefon Norge')}:</h4>
            <a href="tel:+46-46-525-80">33 22 12 90</a>
          </div>
          <div className="col l-3">
            <h4>{T('E-post')}:</h4>
            <a href="mailto:support@cupmanager.net">support@cupmanager.net</a>
      </div>
        </div>*/}
        {/*<div className="row gutter" style={{marginTop: '2rem'}}>
          <div className="col l-4">
            <h3 className="sr-only">{T('Missa inte vår site där du hittar alla turneringar')}!</h3>
            <a href="http://cupinvite.com" target="_blank"> 
              <img src={ci_logo} alt="Cup Invite" style={{width: '50%', margin: '1rem 0 1rem'}} />
              <span className="sr-only">Öppnas i nytt fönster</span>
            </a>
            <p dangerouslySetInnerHTML={{ __html: T('Hitta alla turneringar via vår nya portal, <a href="http://cupinvite.com" target="_blank">cupinvite.com</a>!') }}></p>
          </div>
  </div>*/}
      </div>
    </footer>
  )
}