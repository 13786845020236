import React, {useState, useContext} from 'react';
import {useTranslations} from 'cupman-utils';
import { v4 as uuid } from 'uuid';
import ParticipantModal from './ParticipantModal';
import {SaveContext} from '../../../SaveContext';
var classNames = require('classnames');

const newParticipant = () => ({
  id: uuid(),
  firstName: '',
  lastName: '', 
  contacts: [
    {
      firstName: '', 
      lastName: '', 
      phone: '', 
      email: ''
    }
  ],
  products: []
});


export default function ParticipantsInfoType ({anonymous, setAnonymous, anonymousCount, setAnonymousCount, participants, onSave, spreadEven}) {
  const T = useTranslations("TeamCollect");
  const [modalOpen, setModalOpen] = useState(false);
  const save = useContext(SaveContext);
  const [isSaving, setIsSaving] = useState(false);
  
  return (
    <>
      {spreadEven && <div className="row gutter">
        <div className="col l-10">
        <h2>{T('Hur vill du sköta kontakten')}?</h2>
        </div>
        <div className="col l-5">
          <div className={classNames('part_info_choice', {marked: anonymous})}>
            <div className="input-container">
              <input type="radio" id="share-link" checked={anonymous} onChange={() => setAnonymous(true)} />
              <label htmlFor="share-link">{T('Jag delar själv länken till insamlingen')}</label>
            </div>
            <div className="part_info_extra">
              <p>{T('Efter publicering får du en betallänk. Denna skickar du själv ut till de som ska betala, exempelvis via epost eller sms.')}</p>
              <h4 className="sr-only">{T('Plus')}</h4>
              <p className="tight_p"><i className="fas fa-plus green"></i> {T('De som betalar fyller själva i sina uppgifter när de betalar. Du behöver bara välja hur många de är här.')}</p>
              <h4 className="sr-only">{T('Minus')}</h4>
              <p className="tight_p"><i className="fas fa-minus red"></i> {T('Du måste själv skicka ut betallänken till dina deltagare.')}</p>
            </div>
            <div className={classNames('extra', {active: anonymous})}>
              <label>{T('Fyll i antal deltagare')}</label>
              <input type="number" value={anonymousCount} onChange={e => setAnonymousCount(e.target.value)} />
            </div>

          </div>
        </div>
        <div className="col l-5">
          <div className={classNames('part_info_choice', {marked: !anonymous})}>
            <div className="input-container">
              <input type="radio" id="add-info" checked={!anonymous} onChange={() => {
                setAnonymous(false);
              }} />
              <label htmlFor="add-info">{T('Låt Superinvite sköta kontakt och påminnelser')}</label>
            </div>
            <div className="part_info_extra">
              <p>{T('Fyll i alla deltagare och kontaktpersoner i nästa steg. Därefter hanterar vi alla utskick i form av sms och epost åt dig.')}</p>
              <h4 className="sr-only">{T('Plus')}</h4>
              <p className="tight_p"><i className="fas fa-plus green"></i> {T('Vi hanterar alla utskick automatiskt, som inbjudan och påminnelser.')}</p>
              <h4 className="sr-only">{T('Minus')}</h4>
              <p className="tight_p"><i className="fas fa-minus red"></i> {T('Du måste ha tillgång till kontaktinformationen för dina deltagare.')}</p>
            </div>
            <div className={classNames('extra', {active: !anonymous})}>
              <button aria-expanded="false" className="btn dark-secondary" onClick={() => setModalOpen(true)}>
              {T('Lägg till ny deltagare')} <i className="fas fa-edit"></i>
            </button>
            </div>
          </div>
        </div>
      </div>}

      {!spreadEven && <div className="row gutter">
          <div className="col l-10">
          <button aria-expanded="false" className="btn dark-secondary" onClick={() => setModalOpen(true)}>
            {T('Lägg till ny deltagare')} <i className="fas fa-edit"></i>
          </button>
        </div> 
      </div>}
      <ParticipantModal
        participant={newParticipant()} 
        isNew={true} 
        isOpen={modalOpen}
        isSaving={isSaving}
        closeEditor={() => setModalOpen(false)} 
        onSave={newParticipant => {
          setIsSaving(true)
          onSave([
            newParticipant,
            ...participants
          ]);
          
          save().then(() => {
            setModalOpen(false);
            setIsSaving(false)
          })
        }}
      /> 
    </>
  )
}