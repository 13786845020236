import React, {useState} from 'react';
import Modal from 'react-modal';
import SpreadSum from './SpreadSum';
import SpreadProducts from './SpreadProducts';
import {formatCurrency, productCleared, productClearedFraction} from '../../../utils';
import {useTranslations} from 'cupman-utils';
var classNames = require('classnames');

export default function ProductButton ({product, participants, onDataChange}) {
  const T = useTranslations("TeamCollect");
  const [isOpen, setIsOpen] = useState(false);
  const [spreadSum, setSpreadSum] = useState(product.spreadSum);

  const closeModal = () => {
    setIsOpen(false);
  }

  const fractionCleared = productClearedFraction(participants, product);

  return (
    <>
      <button key={product.id} className={classNames('btn dark-secondary', {cleared: productCleared(participants, product)})} onClick={() => setIsOpen(true)}>
        {!productCleared(participants, product) && T('Fördela ')} {product.name} {!productCleared(participants, product) && fractionCleared.n > 0 && (fractionCleared.n  + "/" + fractionCleared.d)} {productCleared(participants, product) && <i className="far fa-check"></i>}
      </button>

      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
        contentLabel={product.name}>

        <h3>{product.name}</h3>
        <p>{T('Antal')}: <strong>{product.count}</strong> {T('st.')} | {T('Summa')}: <strong>{formatCurrency(product.amount)}</strong></p>

        {false && <div className="info-highlight">
          <ul>
            <li>{T('Fördelar du summa fördelas produkternas kostnad jämnt över mellan de deltagare du markerar')}.</li>
            <li>{T('Fördelar du produkter kan du välja hur många produkter varje deltagare ska betala för')}.</li>
          </ul>
        </div>}

        <h4 >{T('Fördela summa eller produkter')}</h4>

        <fieldset className="allocate-participant">
          <legend className="sr-only">{T('Fördela kostnad på deltagare')}</legend>
          <div className="input-container">
            <input type="radio" id="allocate-participant-01" name="allocate" checked={spreadSum} onChange={() => setSpreadSum(true)} />
            <label htmlFor="allocate-participant-01">{T('Fördela summan jämnt över deltagarna')}</label>
          </div>
          <div className="input-container">
            <input type="radio" id="allocate-participant-02" name="allocate" checked={!spreadSum} onChange={() => setSpreadSum(false)} />
            <label htmlFor="allocate-participant-02">{T('Fördela varje produkt på deltagarna')}</label>
          </div>
        </fieldset>


        <SpreadSum style={{display:  spreadSum ? 'block':'none' }} setIsOpen={setIsOpen} participants={participants} product={product} onSave={(userProducts) => {

          const productsCheckedLength = Object.values(userProducts).filter(val => val).length;


          onDataChange(participants.map(participant => {
            const checked = userProducts[participant.id];

            const index = participant.products.findIndex(p => p.id === product.id);

            if(checked){
              if(index > -1){
                participant.products[index]["fraction"]=[1, productsCheckedLength];
              } else {  
                participant.products.push({
                  id: product.id, 
                  moneyIds: product.moneyIds,
                  fraction: [1, productsCheckedLength]
                })
              }
            } else if(index > -1) {
              participant.products.splice(index, 1);
            }

            return participant;
          }), {
            ...product,
            spreadSum: true
          })
          
        }}/>

        <SpreadProducts style={{display: spreadSum ? 'none':'block' }} setIsOpen={setIsOpen} participants={participants} product={product} onSave={(userProducts) => {

          const productsCheckedLength = product.count;

          onDataChange(participants.map(participant => {
            const participantCount = userProducts[participant.id];

            console.log(participantCount);

            const index = participant.products.findIndex(p => p.id === product.id);

            if(participantCount){
              if(index > -1){
                participant.products[index]["fraction"]=[participantCount, productsCheckedLength];
              } else {  
                participant.products.push({
                  id: product.id, 
                  moneyIds: product.moneyIds,
                  fraction: [participantCount, productsCheckedLength]
                })
              }
            } else if(index > -1) {
              participant.products.splice(index, 1);
            }

            return participant;
          }),{
            ...product,
            spreadSum: false
          })
        }} />

        <button onClick={() => setIsOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>
      </Modal>
    </>
  )
}