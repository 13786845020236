import React, { useState, useEffect, useRef, useReducer } from 'react';
import { useTranslations } from 'cupman-utils';
var classNames = require('classnames');


window.CupMan = {
  wr: {
    cupId: 0
  }
}

function useScript(url) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (!loading) return;
    
    const script = document.createElement('script');
    script.src = url;
    script.async = false;
    script.addEventListener("load", () => {
      setLoading(false);
    })
    document.body.appendChild(script);
    return () => {
      
    }  
  }, [url, loading]);
  return !loading;
}


/*function useStylesheet(url) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = "stylesheet";
    link.href = url;
    link.addEventListener("load", () => {
      setLoading(false);
    })
    document.head.appendChild(link);
    return () => {
      document.head.removeChild(link);
    }  
  }, [url]);
  return !loading;
}*/

export function CmPay({ orderItems, paymentConfig, currencyCode, nationId, lang, edition, email, validate, performRequest, onPaid, onError, fatalError, firstError, isValid }) {
  var loadedScripts = [
    useScript("//ajax.googleapis.com/ajax/libs/jquery/1.7.1/jquery.min.js", true),
    useScript("//ajax.googleapis.com/ajax/libs/angularjs/1.2.16/angular.min.js"),
    useScript("//ajax.googleapis.com/ajax/libs/angularjs/1.2.16/angular-resource.min.js"),
    useScript("//ajax.googleapis.com/ajax/libs/angularjs/1.2.16/angular-sanitize.min.js"),
    useScript(`https://code.angularjs.org/1.2.16/i18n/angular-locale_${lang==="nb" ? "no" : lang}.js`),
    useScript("https://static.cupmanager.net/js/Base.js?v=0"),
    useScript("https://static.cupmanager.net/js/Registration.js?v=0"),
    useScript("https://x.klarnacdn.net/kp/lib/v1/api.js"),
    useScript("https://js.stripe.com/v3/"),
    useScript(`https://parts.cupmanager.net/${lang}/teamfund_pay_translations/`),
  ];
  

  var locale = "en_US";
  if (lang === "nb") {
    locale = "nb_NO";
  }
  if (lang === "sv") {
    locale = "sv_SE";
  }
  
  var allLoaded = loadedScripts.every(_ => _);
  return allLoaded
  ? <CmPayInner {...{ orderItems, paymentConfig, currencyCode, nationId, lang, locale, edition, email, validate, performRequest, onPaid, onError, fatalError, firstError, isValid }} />
  : '';
}
function CmPayInner({ orderItems, paymentConfig, currencyCode, nationId, lang, locale, edition, email, validate, performRequest, onPaid, onError, fatalError, firstError, isValid }) {
  const ref = useRef();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const [cmPayPayload, setCmPayPayload] = useState();
  const [paying, setPaying] = useState(false);
  const T = useTranslations("TeamCollect");
  
  useEffect(() => {
    init(currencyCode, paymentConfig, nationId, edition, email, lang, locale);
    var app = window.angular.module("TeamFundPay", ['CupManagerRegistration']);
    app.controller("TeamFundPayController", ["$scope", function ($scope) {
      $scope.cmPayPayload = {
        orderItems: orderItems,
        paymentMethods: ['card', 'klarna']
      };

      $scope.$watch("cmPayPayload", function () {
        setCmPayPayload($scope.cmPayPayload);
        forceUpdate();
      }, true);

      $scope.$watch("email", function () {
        setCmPayPayload($scope.cmPayPayload);
        forceUpdate();
      }, true);
    }]);

    window.angular.bootstrap(ref.current, ['TeamFundPay']);
  }, []);

  useEffect(() => {
    if( window.economyBanner ){
      window.economyBanner = { "currency": { "code": currencyCode }, "nationId": nationId, "regClubFormId": paymentConfig.formId, "shipping": {"owner":{"email":email}}  };
    }
  },[email])

  function pay() {
    setPaying(true);
    try {
      validate && validate({isValidCard: !!cmPayPayload.isValidCard});
      if (!cmPayPayload.selectedPaymentMethod) {
        throw Object.assign(new Error(), {
          code: "no_payment_method",
          message: T("Please choose a payment method")
        })
      }
      cmPayPayload.pay(performRequest).then(r => {
        setPaying(false);
        onPaid(r);
      }).catch(error => {
        setPaying(false);
        onError(error);
      });
    } catch (e) {
      onError(e);
    }
  }

  return <div id="cupman" style={{background: "white", margin: "0 auto" }} className={classNames({'error-disabled': fatalError})}>
    <div ref={ref} 
      dangerouslySetInnerHTML={{
        __html: `
        <div ng-controller="TeamFundPayController">
          <div cm-pay="cmPayPayload"></div>
        </div>
      ` }} />
    
    <button onClick={e => pay()} disabled={fatalError || (firstError && !isValid)} className="btn btn-small" style={{width: "100%"}}>
      {T('Betala')}
    </button>
  </div>;
}
function init(currencyCode, paymentConfig, nationId, edition, email, lang, locale) {
  window.paymentConfig = paymentConfig;
  var klarnaConfig = paymentConfig.klarna;
    
  window.CupMan.wr.currency = {
    code: currencyCode
  };
  window.CupMan.wr.language = {
    locale
  }
  
  window.economyBanner = { "currency": { "code": currencyCode }, "nationId": nationId, "regClubFormId": paymentConfig.formId, "shipping": {"owner":{"email":email}}  };
  window.edition = edition;
  if (window.paymentConfig && window.paymentConfig.active) {
    window.stripe = new window.Stripe(window.paymentConfig.stripePublishableKey, {
      locale: lang==="nb"?"no":lang
    });
    window.stripe_elements = window.stripe.elements({
      fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,300,700,400,600' }]
    });
  }
}
