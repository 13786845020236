import React from 'react';
import Modal from 'react-modal';
import ParticipantEditor from './ParticipantEditor';
import {useTranslations} from 'cupman-utils';


export default function ParticipantModal ({participant, isNew, isOpen, setModalOpen, onSave, onDelete, isSaving, isDeleting, closeEditor}) {

  const T = useTranslations("TeamCollect");
  const participantLabel = isNew ? T('Lägg till ny deltagare') : T('Redigera ') + participant.firstName + ' ' + participant.lastName;
  // const closeModal = () => {
  //   setModalOpen(false);
  // }

  return (
    <>
      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={closeEditor}
        shouldCloseOnOverlayClick={true}
        contentLabel={participantLabel}
      >
        <ParticipantEditor participantLabel={participantLabel} participant={participant} onSave={(p) => onSave(p)} onDelete={onDelete} isNew={isNew} isSaving={isSaving} isDeleting={isDeleting} />

        <button onClick={closeEditor} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>
      </Modal>
    </>
  )
}