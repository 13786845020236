import React from 'react';
import {formatCurrency} from '../../utils';
import {useTranslations} from 'cupman-utils';

export default function SumTeamCash({teamCash}) {
  const T = useTranslations("TeamCollect");

  return (
    <>
      {teamCash > 0 && <fieldset>
        <legend className="sr-only">{T('Produkter')}</legend>
        <table>
          <tbody>
            <tr className="summary-sum">
              <td colSpan="1">{T('Angiven lagkassa')}:</td>
              <td colSpan="2" className="red">{formatCurrency(teamCash)}</td>
            </tr>
          </tbody>
        </table>
      </fieldset>}
    </>
  )
}