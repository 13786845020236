import React, {useState} from 'react';
import ParticipantContactForm from './ParticipantContactForm';
import Modal from 'react-modal';
import {useTranslations} from 'cupman-utils';

export default function ParticipantContact({contact, participant, onSave, onDelete, index, id}) {
  const T = useTranslations("TeamCollect");

  const [isOpen, setModalOpen] = useState(false);
  const closeModal = () => {
    setModalOpen(false);
  }

  return (
    <>
      <button className="btn-contact" onClick={() => setModalOpen(true)}>
        {T('Redigera')}
      </button>
      <Modal 
        className="modal"
        overlayClassName="overlay"
        isOpen={isOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}>
        <h2>{T('Redigera kontakt')}</h2>

        <p>{T('Ifall du ändrar på email och/eller telefonnummer så skickas ett nytt utskick av inbjudan automatiskt när du sparar.')}</p>

        <ParticipantContactForm contact={contact} onSave={onSave} onDelete={onDelete} participant={participant} setModalOpen={setModalOpen} index={index} id={id} />

        <button onClick={() => setModalOpen(false)} className="close btn">
          <i className="fal fa-times"></i> 
          <span className="sr-only">{T('Stäng')}</span>
        </button>
      </Modal>
    </>
  )
}
