import React, {useState, useContext} from 'react';
import {divideMoney, formatCurrency, participantProductState} from '../../../utils';
import {useTranslations} from 'cupman-utils';
import {SaveContext} from '../../../SaveContext';
import loadingCircle from '../../../../img/loading-circle-white.svg';

export default function SpreadSum ({participants, product, setIsOpen, onSave, style}) {
  const T = useTranslations("TeamCollect");
  const save = useContext(SaveContext);
  const [isSaving,setIsSaving] = useState(false);

  const [spreadSumCount, setSpreadSumCount] = useState(participantProductState(participants, product));

  const countSelectedProducts = Object.values(spreadSumCount).reduce((total, value) => {
    return total + value;
  }, 0);
  

  return (
    <div className="participants-product" style={style}>
      <h4>{T('Välj hur många X varje deltagare ska betala för', product.name)}:</h4>
      {participants.sort((a,b)=>{return a.lastName.localeCompare(b.lastName)}).map(_p => 
        <div className="row" key={_p.id}>
          <div className="product-name col s-5 d-10">
            {_p.firstName} {_p.lastName}
          </div>
          <div className="buttons col d-4 s-2">
            <button className="btn tool" disabled={spreadSumCount[_p.id] <= 0} onClick={() => {
              setSpreadSumCount({
                ...spreadSumCount,
                [_p.id] : spreadSumCount[_p.id] -= 1
              });
            }}>
              <i className="fas fa-minus-square" aria-hidden="true"></i>
              <span className="sr-only">{T('Ta bort X av', 1)} {product.name}</span>
            </button>
            <button className="btn tool"
            disabled={countSelectedProducts >= product.count}
            onClick={() => {
              setSpreadSumCount({
                ...spreadSumCount,
                [_p.id] : spreadSumCount[_p.id] += 1
              });
            }}>
              <i className="fas fa-plus-square" aria-hidden="true"></i>
              <span className="sr-only">{T('Lägg till X av', 1)} {product.name}</span>
            </button>
          </div>
          <div className="product-count col d-3 s-1">
            {spreadSumCount[_p.id]} {T('st.')}
          </div>
          <div className="col d-3 s-2">
            {formatCurrency(divideMoney(product.amount, product.count) * spreadSumCount[_p.id])}
          </div>
        </div>
      )}
      {countSelectedProducts < product.count &&
        <div className="row alert">
          <div className="col l-10">
      <i className="fas fa-exclamation-circle" aria-hidden="true"></i> {product.count - countSelectedProducts} {product.name} {T('kvar att fördela. (Du måste inte fördela alla om du inte vill)')}
          </div>
        </div>
      }

      {countSelectedProducts === product.count &&
        <div className="row done">
          <div className="col l-10">
          <i className="fas fa-check-circle"></i> {product.name} {T('fördelade')}.
          </div>
        </div>
      }

      <button className="btn save" onClick={() => {
        onSave(spreadSumCount);
        setIsSaving(true);
          save().then(()=>{
            setIsSaving(false);
            setIsOpen(false);
          });}
      }>{T('Spara')}{
        isSaving ? 
        <> 
          <span className="sr-only">{T('Autosparar, vänta')}</span>
          <img src={loadingCircle} alt="" className="loading-circle" />
        </> : <i className="far fa-angle-right"></i>
        }</button>
    </div>
  )
}