import React from 'react';
import {useTranslations} from 'cupman-utils';
import { joinAnd } from '../../utils.js'

export default function OtherContacts ({otherContacts}) {
  const T = useTranslations("TeamCollect");

  var outputContacts = otherContacts.map(c => `<strong>${c.firstName} ${c.lastName}</strong> (${c.phone} & ${c.email})`);

  return (
    
    <div className="other-contact" dangerouslySetInnerHTML={{__html: T('Denna inbjudan har även skickats till X.', joinAnd(outputContacts, T('och')))}}>
    </div>

  )
}