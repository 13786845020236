import React, {createContext} from 'react';

export const SaveContext = createContext();

export const SaveProvider = props => {

  return (
    <SaveContext.Provider>
      {props.children}
    </SaveContext.Provider>
  )
}