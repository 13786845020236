import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import {animationDuration} from '../../../utils';
import {useTranslations} from 'cupman-utils';
var classNames = require('classnames');

export default function Participant ({participant, openEditor}) {
  const T = useTranslations("TeamCollect");
  // TOGGLE ACCORDION
  const [accHeight, setAccHeight] = useState(0);
  const toggleAcc = () => setAccHeight(accHeight === 0 ? 'auto' : 0);
  // / TOGGLE ACCORDION
 

  return (
    <div className={ classNames('participant', {active: accHeight !== 0})}>
      <div className="participant-info">
        <span className="name">{participant.firstName} {participant.lastName}</span>
        <div className="participant-actions">

          <button className="participant-edit" onClick={openEditor}><span className="desktop"></span>{T('Redigera')} <i className="fas fa-edit"></i></button>
          

          <button className="participant-accordion" onClick={() => {
            toggleAcc();
          }} aria-expanded={accHeight !== 0}><span className="sr-only">{T('Expandera')}</span></button>
        </div>
      </div>
      <AnimateHeight
        duration={ animationDuration }
        height={ accHeight }
      >
        <div className="participant-contacts" aria-hidden={accHeight !== 0}>
          <h3 className="sr-only">{T('Kontaktpersoner')}</h3>
          {participant.contacts.map((contact, i) => 
            <div className="contact row" key={i}>
              <div className="col l-3">
                <h4>{T('Kontaktperson')}</h4>
                <span>{contact.firstName} {contact.lastName}</span>
              </div>
              <div className="col l-3">
                <h4>{T('Telefon')}</h4>
                <span>{contact.phone}</span>
              </div>
              <div className="col l-4">
                <h4>{T('E-post')}</h4>
                <span>
                  <a href={`mailto: ${contact.email}`}>{contact.email}</a>
                </span>
              </div>
            </div>
          )}
        </div>
      </AnimateHeight>
    </div>
  )
}