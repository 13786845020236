import React, {useState, useContext} from 'react';
import Participants from './Participants';
import DynamicSum from '../../DynamicSum';
  import ProductsTable from '../../DynamicSum/ProductsTable';
  import ProductRow from '../../DynamicSum/ProductRow';
import ParticipantsInfoType from './ParticipantsInfoType';
import {useTranslations} from 'cupman-utils';
import ConfirmDialog from '../../ConfirmDialog';
import { v4 as uuid } from 'uuid';
import {SaveContext} from '../../../SaveContext';
import loadingCircle from '../../../../img/loading-circle-white.svg';
import {useHistory} from 'react-router-dom';
import { myScrollTop } from '../../../utils';
var classNames = require('classnames');

const anoParticipant = () => ({
  id: uuid(),
  firstName: '',
  lastName: '', 
  contacts: [],
  products: [],
  anonymous: true
});

const isValid = (participants, anonymous, anonymousCount) => {
  if(anonymous) {
    if(anonymousCount > 0) {
      return true;
    }
  } else {
    if(participants.length > 0) {
      return true;
    }
  }
  
  return false;
}

export default function Content ({uiData, data, onDataChange}) {
  const save = useContext(SaveContext);
  const [isSaving, setIsSaving] = useState(false);
  const T = useTranslations("TeamCollect");
  const [anonymousCount, setAnonymousCount] = useState(data.anonymousCount);
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const history = useHistory();

  const addAnoParticipants = () => {
    return Array.from({length: parseInt(anonymousCount)}).map(_ => _ = anoParticipant());
  }
  const nextStep = () => {

    setIsSaving(true);

    data.anonymous && onDataChange({
      ...data,
      anonymousCount: anonymousCount,
      participants: addAnoParticipants()
    });

    save().then(()=> {
      setIsSaving(false);
      history.push(`/wizard/${data.id}/products`);
      myScrollTop();
    });
  }

  return (
    <main className="content-wrapper">
      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          <h1>{data.name}</h1>
        </div>
      </div>
      <div>
        <div className="row gutter">

          {/* LEFT COLUMN */}
          <div className="col l-5 summary_info">
            <h2>{T('Steg 2: Deltagare')}</h2>
            {data.spreadEven && <>
              <p>{T('Vill du att Superinvite ska sköta kontakten med de som ska betala eller vill du sköta det själv?')}</p>
              <p>{T('Väljer du att själv dela en länk till insamlingen behöver du bara ange antalet deltagare. Sedan håller systemet reda på vem och hur många som betalt.')}</p>
              <p>{T('Om Superinvite ska sköta kontakten lägger du in deltagarna som ska vara med och betala. För varje deltagare lägger du också till kontaktpersoner som kommer att få en betalningslänk när den publiceras.')}</p>
            </>}
            {!data.spreadEven && <>
              <p>{T('Lägg till deltagarna som ska vara med och betala.')}</p>
              <p>{T('För varje deltagare lägger du också till kontaktpersoner som kommer få en länk till insamlingen när insamlingen publiceras.')}</p>
            </>}
          </div>
            

          {/* RIGHT COLUMN */}
          <div className="col l-5 products-column">
          <DynamicSum status={2}>
              <ProductsTable products={data.products} teamCashText={true}>
                {data.products.map((product, i) => <ProductRow product={product} checkBoxes={false} i={i} key={i} 
                  onProductChange={newProduct => onDataChange({
                    ...data,
                    products: data.products.map(_product => _product===product ? newProduct : _product)
                  })}
                />)}
              </ProductsTable>
            </DynamicSum>
          </div>
        </div>
      </div>
      
      <ParticipantsInfoType 
        spreadEven={data.spreadEven} 
        anonymous={data.anonymous} 
        setAnonymous={val => {

          onDataChange({
            ...data,
            anonymous: val,
            participants: !val ? data.participants.filter(p => !p.anonymous) : data.participants
          });
        }} 
        anonymousCount={anonymousCount} 
        setAnonymousCount={val => {
          setAnonymousCount(val)
        }}
        participants={data.participants} 
        onSave={newParticipants => {
          onDataChange({
            ...data,
            participants: newParticipants
          });
        }}
      />

      {false && !data.anonymous && 
      <div className="row gutter">
        <div className="col l-10">
          <div className="info-highlight">
            <i className="fas fa-info-circle"></i> {T('Automatiska påminnelser text.')}
          </div>
        </div>
      </div>}

      {(!data.spreadEven || !data.anonymous) && <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10">
          <Participants participants={data.participants} 
              onSave={newParticipants => {
                onDataChange({
                  ...data,
                  participants: newParticipants
                });
              }} />
        </div>
      </div>}
      <div className="row gutter">
        {/* FULL COLUMN COLUMN */}
        <div className="col l-10 nav-btns">
          <button onClick={() => history.push(`/wizard/${data.id}/`)} className="btn delete previous"><i className="far fa-angle-left"></i> {T('Upplägg')}</button>
          <button onClick={() => {
            (data.participants.filter(p => !p.anonymous).length > 0 && data.anonymous) ? setConfirmDialogVisible(true) : nextStep();
          }} 
          disabled={!isValid(data.participants, data.anonymous, anonymousCount) || isSaving} className={classNames('btn dark-primary next', {saving: isSaving})}>
            {T('Produkter')} {
            isSaving ? 
            <> 
              <span className="sr-only">{T('Autosparar, vänta')}</span>
              <img src={loadingCircle} alt="" className="loading-circle" />
            </> : <i className="far fa-angle-right"></i>
            }
          </button>

          <ConfirmDialog visible={confirmDialogVisible} setVisibility={() => setConfirmDialogVisible(false)} functionToRun={() => {
            setConfirmDialogVisible(false);
            nextStep();
          }} sendTxt={T('Ok')} msg={T('Om du fortsätter så raderas de deltagare samt kontaktpersoner som redan är inlagda.')} />

        </div>
      </div>
    </main>
  )
}