import React from 'react';
import {formatCurrency} from '../../../utils';
import {useTranslations} from 'cupman-utils';

export default function TableTrTeamCash ({usedTeamCash}) {
  const T = useTranslations("TeamCollect");

  return (
    <tr className="red">
      <td colSpan="2">{T('Lagkassa')}:</td>
      <td colSpan="1">- {formatCurrency(usedTeamCash)}</td>
    </tr>
  )
  
}