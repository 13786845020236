import React, {useContext, useState} from 'react';
import ModalPreview from './ModalPreview';
import SmartPhonePreview from './SmartPhonePreview';
import {useTranslations} from 'cupman-utils';
import { SaveContext } from '../../../SaveContext';

export default function KnownParticipants ({data, onDataChange}) {
  const T = useTranslations("TeamCollect");
  const [isOpen, setIsOpen] = useState(false);
  const save = useContext(SaveContext);

  return (
    <div className="col l-10">
      {/* LEFT COLUMN */}
      <div className="row gutter" style={{background : 'var(--color-gray-5)', 'marginTop' : '2rem', padding : '1rem 0'}}>
        <div className="col l-10"><h2>{T('Förhandsvisa inbjudan')}</h2></div>
        <div className="col l-5">
          <h3>SMS</h3>
          
          <SmartPhonePreview fund={data} />
        </div>
        {/* RIGHT COLUMN */}
        <div className="col l-5">
          <h3>{T('Epost')}</h3>

          <h4 dangerouslySetInnerHTML={{__html: T('Lägg in en personlig text till deltagarnas inbjudan (valfritt)<span>(Lägg till text och förhandsgranska för att se resultat)</span>')}} />
          <textarea value={data.emailTeamText} onChange={(e) => onDataChange({
            ...data,
            emailExtraText: e.target.value
          })}>
          </textarea>

          <button className="btn dark-secondary" type="button" onClick={() => {
              save().then(()=>setIsOpen(true))
            }}>{T('Förhandsvisa e-postutskick / inbjudan')}</button>
          <ModalPreview isOpen={isOpen} setIsOpen={setIsOpen} data={data} />
        </div>
      </div>
    </div>
  )
}